import { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { ExternalLink, MessageCircle } from "lucide-react";
import InquiryForm from "../../features/product/InquiryForm";

interface ProductCardProps {
  product: {
    _id: { $oid: string },
    brand: string,
    name: string,
    image: string,
    category: string,
  };
}

export function ProductCard({ product }: ProductCardProps) {
  const [isInquiryOpen, setInquiryOpen] = useState(false);
  if(typeof(product.image) !== "string"){
    product.image = product.thumbnail;
  }

  return (
    <>
      <Link to={`/product/${product._id.$oid}`}>
        <motion.div
          className="group relative bg-white rounded-xl shadow-sm hover:shadow-lg transition-shadow overflow-hidden"
          whileHover={{ scale: 1.05 }} // Scaling on hover without need for additional state
        >
          {/* Image Container */}
          <div className="aspect-square overflow-hidden">
            <motion.img
              src={product.image}
              alt={`Image of ${product.name}`} // Improved alt text for accessibility
              className="w-full h-full "
              transition={{ duration: 0.3 }}
            />
          </div>

          {/* Content */}
          <div className="p-4">
            <div className="mb-2">
              <span className="inline-block px-2 py-1 text-xs font-medium bg-violet-100 text-violet-800 rounded-full">
                {product.brand}
              </span>
            </div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              {product.name}
            </h3>
            <p className="text-sm text-gray-500 mb-4">
              {product.category == "Light Trucks"
                ? "Mini Trucks"
                : product.category}
            </p>

            {/* Action Buttons */}
            <div className="flex gap-2">
              <button
                onClick={() => setInquiryOpen(true)}
                className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-violet-600 text-white rounded-lg hover:bg-violet-700 transition-colors"
              >
                <MessageCircle className="w-4 h-4" />
                <span>Enquiry</span>
              </button>
              <Link
                to={`/product/${product._id.$oid}`}
                className="flex-1 flex items-center justify-center gap-2 px-4 py-2 border border-violet-600 text-violet-600 rounded-lg hover:bg-violet-50 transition-colors"
              >
                <ExternalLink className="w-4 h-4" />
                <span>View</span>
              </Link>
            </div>
          </div>
        </motion.div>
      </Link>

      {/* Inquiry Form Modal */}
      <InquiryForm
        isOpen={isInquiryOpen}
        onClose={() => setInquiryOpen(false)}
        id={product._id.$oid}
      />
    </>
  );
}
