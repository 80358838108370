import React from "react";
import {
  Calendar,
  Scale,
  Truck,
  Fuel,
  Settings,
  Gauge,
  MapPin,
  Heart,
  Phone,
  Mail,
  Check,
  X,
  ChevronRight,
  ShipWheel,
  LifeBuoy,
  AirVent,
  Ban,
  TruckIcon,
} from "lucide-react";
import ImageGallery from "./ImageGallery";
import SpecificationCard from "./SpecificationCard";
import ValiditySection from "./ValiditySection";
import DocumentStatus from "./DocumentStatus";
import RegistrationSection from "./RegistrationSection";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function VehicleDetails({ vehicle, onContactClick }) {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const specs = [
    {
      icon: <Calendar className="w-5 h-5" />,
      label: t("year"),
      value: vehicle?.manufacture_year,
    },
    {
      icon: <Scale className="w-5 h-5" />,
      label: t("weight"),
      value: vehicle?.weight,
    },
    {
      icon: <Truck className="w-5 h-5" />,
      label: t("payload"),
      value: vehicle?.payload_capacity,
    },
    {
      icon: <Fuel className="w-5 h-5" />,
      label: t("fuelType"),
      value: vehicle?.fuel_type,
    },
    {
      icon: <Settings className="w-5 h-5" />,
      label: t("transmission"),
      value: vehicle?.transmission,
    },
    {
      icon: <Gauge className="w-5 h-5" />,
      label: t("displacement"),
      value: vehicle?.engine?.displacement,
    },
    {
      icon: <Gauge className="w-5 h-5" />,
      label: t("power"),
      value: vehicle?.engine?.power,
    },
    {
      icon: <Gauge className="w-5 h-5" />,
      label: t("torque"),
      value: vehicle?.engine?.torque,
    },
  ];
  console.log(vehicle);
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Breadcrumb */}
        <nav className="flex items-center justify-between space-x-2 text-sm mb-8">
          <div className="left flex space-x-2 items-center">
            <span className="text-gray-500">{t("vehicles")}</span>
            <ChevronRight className="w-4 h-4 text-gray-400" />
            <span className="text-gray-500">{vehicle?.category}</span>
            <ChevronRight className="w-4 h-4 text-gray-400" />
            <span className="text-gray-900 font-medium">{vehicle?.name}</span>
          </div>
          <div className="right">
            <button
              onClick={() => changeLanguage("en")}
              className="px-4 py-2 mr-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-200"
            >
              English
            </button>
            <button
              onClick={() => changeLanguage("hi")}
              className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 transition-all duration-200"
            >
              Hindi
            </button>
          </div>
        </nav>

        <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Image Gallery */}
            <ImageGallery
              image={vehicle?.image}
              thumbnail={vehicle?.thumbnail}
            />

            {/* Details Section */}
            <div className="p-8">
              {/* Header */}
              <div className="flex justify-between items-start">
                <div>
                  <h1 className="text-3xl font-bold text-gray-900 mb-2">
                    {vehicle?.name}
                  </h1>
                  <div className="flex items-center space-x-4 text-gray-600">
                    <span className="font-medium">{vehicle?.brand}</span>
                    <span>•</span>
                    <span>{vehicle?.model}</span>
                    <span>•</span>
                    <span className="text-green-600 font-semibold">
                      ₹{vehicle?.cost}
                    </span>
                  </div>
                </div>
                <button className="p-2 rounded-full hover:bg-gray-100 transition-colors">
                  <Heart className="w-6 h-6 text-gray-400 hover:text-red-500" />
                </button>
              </div>

              {/* Location */}
              {vehicle?.location && (
                <div className="mt-4 flex items-center text-gray-600">
                  <MapPin className="w-5 h-5 mr-2" />
                  <span>{vehicle?.location.join(", ")}</span>
                </div>
              )}

              {/* Specifications Grid */}
              <div className="mt-8">
                <h2 className="text-lg font-semibold mb-4">
                  {t("Specifications")}
                </h2>
                <div className="grid grid-cols-3 gap-6">
                  {specs.map((spec, index) => (
                    <SpecificationCard key={index} {...spec} />
                  ))}
                </div>
              </div>

              {/* Dimensions */}
              <div className="mt-8">
                <h2 className="text-lg font-semibold mb-4">
                  {t("Dimensions")}
                </h2>
                <div className="bg-gray-50 p-4 rounded-xl grid grid-cols-3 gap-4 text-center">
                  <div>
                    <div className="text-sm text-gray-500">{t("Length")}</div>
                    <div className="font-medium text-gray-900">
                      {vehicle?.length}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-500">{t("Width")}</div>
                    <div className="font-medium text-gray-900">
                      {vehicle?.width}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-500">{t("Height")}</div>
                    <div className="font-medium text-gray-900">
                      {vehicle?.height}
                    </div>
                  </div>
                </div>
              </div>

              {/* Validities */}
              <ValiditySection validities={vehicle?.validities} />

              <RegistrationSection
                registration={[
                  vehicle?.registration?.registration_state,
                  vehicle?.registration?.registration_city,
                ]}
              />

              {/* Description */}
              {vehicle?.description && (
                <div className="mt-8">
                  <h2 className="text-lg font-semibold mb-4">
                    {t("Description")}
                  </h2>
                  <p className="text-gray-600 whitespace-pre-line">
                    {vehicle?.description}
                  </p>
                </div>
              )}

              {/* Documents Status */}
              <DocumentStatus documents={vehicle?.documents} />

              {/* Contact Button */}
              <div className="mt-8 pt-8 border-t">
                <button
                  onClick={onContactClick}
                  className="w-full bg-blue-600 text-white py-3 px-6 rounded-xl font-medium
                    hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center space-x-2"
                >
                  <Phone className="w-5 h-5" />
                  <span>{t("contact")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
