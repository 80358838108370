import React, { useState, useEffect } from "react";
import axios from "axios";
import VehicleDetails from "../features/product/ProductDetail";
import { useParams } from "react-router-dom";
import InquiryForm from "../features/product/InquiryForm";

export default function ProductPage() {
  const [vehicle, setVehicle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const param = useParams();
  const [isInquiryOpen, setInquiryOpen] = useState(false);

  useEffect(() => {
    const fetchVehicle = async () => {
      try {
        const response = await axios.get(
          "/api/vehicles/" + param.id
        );
        setVehicle(response.data);
      } catch (err) {
        setError("Failed to fetch vehicle details");
      } finally {
        setLoading(false);
      }
    };

    fetchVehicle();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="text-red-500 text-xl mb-2">Error</div>
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  if (!vehicle) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="text-xl mb-2">Vehicle Not Found</div>
          <p className="text-gray-600">
            The requested vehicle could not be found.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <VehicleDetails
        vehicle={vehicle}
        onContactClick={() => {
          setInquiryOpen(true);
        }}
      />
      <InquiryForm
        isOpen={isInquiryOpen}
        onClose={() => setInquiryOpen(false)}
        id={param.id}
      />
    </>
  );
}
