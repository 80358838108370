import React, { useState, useEffect } from "react";
import Login from "./Login";
import AdminPage from "../components/AdminPage";

const WithAuth = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn"); // Check login status in localStorage
    if (isLoggedIn === "true") {
      setLoggedIn(true);
    }
  }, []);

  // Handle setting the login state
  const handleLogin = (status) => {
    setLoggedIn(status);
    if (status) {
      localStorage.setItem("isLoggedIn", "true"); // Store login status in localStorage
    } else {
      localStorage.removeItem("isLoggedIn"); // Remove login status if logged out
    }
  };

  // If not logged in, show the login page
  if (!loggedIn) {
    return <Login setLoggedIn={handleLogin} />;
  }

  // If logged in, render the AdminPage
  return <AdminPage setLoggedIn={handleLogin} />;
};

export default WithAuth;
