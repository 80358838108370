import React, { useEffect } from 'react'
import ProductDetail from '../features/product/ProductDetail'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import AIbot from '../components/AIbot';

function Product() {
  useEffect(() => {
    // Scroll to top when the page is loaded or component is mounted
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Navbar />
        <ProductDetail />
        <AIbot />
        <Footer />
    </div>
  )
}

export default Product