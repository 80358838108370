export const truckData = [
  {
    category: "Light Trucks",
    trucks: [
      {
        name: "Tata Ace",
        manufacture_year: 2022,
        brand: "Tata",
        model: "Ace",
        area: "India",
        height: "66.5 inches",
        width: "54.5 inches",
        length: "129.5 inches",
        weight: "950 kg",
        cost: 600000,
        wheel_count: 4,
        payload_capacity: "1000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "702 cc",
          power: "30 HP",
          torque: "55 Nm",
        },
        transmission: "Manual",
        features: [
          "Power Steering",
          "Comfortable Cabin",
          "Low Turning Radius",
          "Durable Chassis",
        ],
        description:
          "The Tata Ace is a versatile light truck ideal for city logistics. With a compact design and low turning radius, it is perfect for urban environments. Its fuel-efficient engine and comfortable cabin make it a popular choice for small businesses.",
        image: "https://patelsanghmp.com/uploads/6f2c5bee-684f-4d4f-a202-a24fabe23692.png",
      },
      {
        name: "Mahindra Bolero Pik-Up",
        manufacture_year: 2022,
        brand: "Mahindra",
        model: "Bolero Pik-Up",
        area: "India",
        height: "75 inches",
        width: "65.5 inches",
        length: "141.7 inches",
        weight: "1200 kg",
        cost: 800000,
        wheel_count: 4,
        payload_capacity: "1200 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "2523 cc",
          power: "75 HP",
          torque: "200 Nm",
        },
        transmission: "Manual",
        features: [
          "Power Steering",
          "High Ground Clearance",
          "Robust Build",
          "Good Load Capacity",
        ],
        description:
          "The Mahindra Bolero Pik-Up is built for tough terrains, offering a strong build and high ground clearance. It's perfect for transporting goods in rural areas and ensures reliability with its powerful engine.",
        image: "https://patelsanghmp.com/uploads/2000b154-0398-4b17-b40c-12f05438c16a.png",
      },
      {
        name: "Ashok Leyland Dost",
        manufacture_year: 2022,
        brand: "Ashok Leyland",
        model: "Dost",
        area: "India",
        height: "69.1 inches",
        width: "64.6 inches",
        length: "144.1 inches",
        weight: "1190 kg",
        cost: 700000,
        wheel_count: 4,
        payload_capacity: "1200 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "1478 cc",
          power: "49 HP",
          torque: "125 Nm",
        },
        transmission: "Manual",
        features: [
          "Comfortable Cabin",
          "High Performance",
          "Strong Chassis",
          "Good Mileage",
        ],
        description:
          "The Ashok Leyland Dost is designed for high performance and good mileage. Its strong chassis and comfortable cabin make it ideal for both short and long hauls in urban and rural areas.",
        image: "https://patelsanghmp.com/uploads/187c75c5-6074-48f2-ab71-7ef2258cbfe7.png",
      },
      {
        name: "Isuzu D-Max V-Cross",
        manufacture_year: 2023,
        brand: "Isuzu",
        model: "D-Max V-Cross",
        area: "India",
        height: "71.5 inches",
        width: "74.8 inches",
        length: "207.8 inches",
        weight: "2070 kg",
        cost: 1900000,
        wheel_count: 4,
        payload_capacity: "1000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "1898 cc",
          power: "150 HP",
          torque: "350 Nm",
        },
        transmission: "Automatic",
        features: [
          "4WD System",
          "Touchscreen Infotainment",
          "Leather Seats",
          "Advanced Safety Features",
        ],
        description:
          "The Isuzu D-Max V-Cross combines style and utility, featuring a robust design and advanced safety features. Its powerful engine and 4WD system make it ideal for both urban and off-road adventures.",
        image: "https://patelsanghmp.com/uploads/c7ef41c2-5874-4773-bfeb-00d1e80ec6d5.png",
      },
      {
        name: "Mahindra Scorpio Pik-Up",
        manufacture_year: 2022,
        brand: "Mahindra",
        model: "Scorpio Pik-Up",
        area: "India",
        height: "72 inches",
        width: "69 inches",
        length: "171.2 inches",
        weight: "1900 kg",
        cost: 1100000,
        wheel_count: 4,
        payload_capacity: "1200 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "2523 cc",
          power: "75 HP",
          torque: "200 Nm",
        },
        transmission: "Manual",
        features: [
          "Robust Build Quality",
          "Good Off-Road Capabilities",
          "Spacious Cabin",
          "Air Conditioning",
        ],
        description:
          "The Mahindra Scorpio Pik-Up offers excellent off-road capabilities and a spacious cabin. Its robust build quality ensures durability, making it suitable for tough conditions.",
        image: "https://patelsanghmp.com/uploads/071276fa-2285-400a-a756-9b7c73f8cd4d.png",
      },
      {
        name: "Tata Intra V10",
        manufacture_year: 2022,
        brand: "Tata",
        model: "Intra V10",
        area: "India",
        height: "69.6 inches",
        width: "54.3 inches",
        length: "145.4 inches",
        weight: "980 kg",
        cost: 600000,
        wheel_count: 4,
        payload_capacity: "1000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "798 cc",
          power: "40 HP",
          torque: "75 Nm",
        },
        transmission: "Manual",
        features: [
          "Compact Design",
          "High Fuel Efficiency",
          "Easy Maneuverability",
          "Durable Chassis",
        ],
        description:
          "The Tata Intra V10 is designed for efficiency and ease of use. Its compact size and high fuel efficiency make it ideal for urban deliveries, while its durable chassis ensures longevity.",
        image: "https://patelsanghmp.com/uploads/e4e170bb-775b-4472-8c8c-334a8de27c9c.png",
      },
      {
        name: "Eicher Pro 2049",
        manufacture_year: 2022,
        brand: "Eicher",
        model: "Pro 2049",
        area: "India",
        height: "78.1 inches",
        width: "72.5 inches",
        length: "191.7 inches",
        weight: "2600 kg",
        cost: 850000,
        wheel_count: 4,
        payload_capacity: "2000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "2000 cc",
          power: "90 HP",
          torque: "240 Nm",
        },
        transmission: "Manual",
        features: [
          "High Load Capacity",
          "Strong Performance",
          "Comfortable Cabin",
          "Advanced Safety Features",
        ],
        description:
          "The Eicher Pro 2049 is built for high performance with a focus on safety and comfort. Its strong engine and spacious cabin make it perfect for transporting heavy loads efficiently.",
        image: "https://patelsanghmp.com/uploads/0b2c28ad-3d6d-426d-a50e-5e9f0c1642e4.png",
      },
      {
        name: "Force Trax",
        manufacture_year: 2023,
        brand: "Force",
        model: "Trax",
        area: "India",
        height: "76 inches",
        width: "66.5 inches",
        length: "176.6 inches",
        weight: "2000 kg",
        cost: 700000,
        wheel_count: 4,
        payload_capacity: "1000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "2596 cc",
          power: "90 HP",
          torque: "230 Nm",
        },
        transmission: "Manual",
        features: [
          "Spacious Cabin",
          "High Ground Clearance",
          "Good Load Capacity",
          "Durable Suspension",
        ],
        description:
          "The Force Trax is known for its spacious cabin and durability. With high ground clearance and a strong suspension, it's designed to handle challenging terrains while ensuring comfort for the driver.",
        image: "https://patelsanghmp.com/uploads/5113be95-ecc6-48d5-b40a-20da9094ef11.png",
      },
    ],
  },

  {
    category: "Medium Trucks",
    trucks: [
      {
        name: "Ashok Leyland 1616",
        manufacture_year: 2021,
        brand: "Ashok Leyland",
        model: "1616",
        area: "India",
        height: "90 inches",
        width: "88 inches",
        length: "197 inches",
        weight: "8000 kg",
        cost: 1800000,
        wheel_count: 6,
        payload_capacity: "16000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "160 HP",
          power: "160 HP",
          torque: "450 Nm",
        },
        transmission: "Manual",
        features: [
          "Heavy-Duty Suspension",
          "High Load Capacity",
          "Spacious Cabin",
          "Good Fuel Efficiency",
        ],
        description:
          "The Ashok Leyland 1616 is a robust medium truck designed for heavy-duty applications. With a powerful engine and high payload capacity, it is perfect for transporting goods across long distances. The spacious cabin ensures comfort for the driver, while the heavy-duty suspension enhances stability.",
        image: "https://patelsanghmp.com/uploads/c37f383a-3b5d-4ddf-b4a2-173b97a578f2.png",
      },
      {
        name: "Tata LPT 1613",
        manufacture_year: 2022,
        brand: "Tata",
        model: "LPT 1613",
        area: "India",
        height: "100 inches",
        width: "88 inches",
        length: "204 inches",
        weight: "8500 kg",
        cost: 1600000,
        wheel_count: 6,
        payload_capacity: "15000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "130 HP",
          power: "130 HP",
          torque: "400 Nm",
        },
        transmission: "Manual",
        features: [
          "Robust Chassis",
          "Comfortable Cabin",
          "Power Steering",
          "Excellent Mileage",
        ],
        description:
          "The Tata LPT 1613 is built for durability and efficiency. It features a robust chassis and a powerful engine, making it ideal for both urban and long-haul transportation. The comfortable cabin ensures a pleasant driving experience, complemented by power steering for easier handling.",
        image: "https://patelsanghmp.com/uploads/fb742f83-8b86-44fe-ad42-72e1cd4eb87c.png",
      },
      {
        name: "Mahindra Furio 7",
        manufacture_year: 2022,
        brand: "Mahindra",
        model: "Furio 7",
        area: "India",
        height: "105 inches",
        width: "90 inches",
        length: "220 inches",
        weight: "9500 kg",
        cost: 2200000,
        wheel_count: 6,
        payload_capacity: "17000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "170 HP",
          power: "170 HP",
          torque: "600 Nm",
        },
        transmission: "Manual",
        features: [
          "Advanced Safety Features",
          "High Comfort Level",
          "Spacious Storage",
          "Powerful Performance",
        ],
        description:
          "The Mahindra Furio 7 combines modern design with powerful performance. Engineered for high load capacity and excellent fuel efficiency, it features advanced safety options and a spacious cabin for added comfort during long drives. Ideal for logistics and transportation businesses.",
        image: "https://patelsanghmp.com/uploads/c9b9f88e-e329-4a75-8435-2cb4288a8bc4.png",
      },
      {
        name: "Eicher Pro 6000",
        manufacture_year: 2023,
        brand: "Eicher",
        model: "Pro 6000",
        area: "India",
        height: "95 inches",
        width: "90 inches",
        length: "225 inches",
        weight: "12000 kg",
        cost: 2100000,
        wheel_count: 6,
        payload_capacity: "20000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "200 HP",
          power: "200 HP",
          torque: "700 Nm",
        },
        transmission: "Manual",
        features: [
          "Efficient Fuel Consumption",
          "User-Friendly Controls",
          "Durable Build Quality",
          "High Payload Capability",
        ],
        description:
          "The Eicher Pro 6000 is a versatile medium truck designed for heavy-duty applications. With a powerful engine and exceptional payload capacity, it’s built for performance and reliability. The truck features user-friendly controls and a durable build, making it ideal for various logistical needs.",
        image: "https://patelsanghmp.com/uploads/e48a2947-dd54-4633-b407-185855302f89.png",
      },
      {
        name: "Tata LPK 2518",
        manufacture_year: 2022,
        brand: "Tata",
        model: "LPK 2518",
        area: "India",
        height: "105 inches",
        width: "90 inches",
        length: "220 inches",
        weight: "11000 kg",
        cost: 2400000,
        wheel_count: 6,
        payload_capacity: "18000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "180 HP",
          power: "180 HP",
          torque: "600 Nm",
        },
        transmission: "Manual",
        features: [
          "Strong Frame Structure",
          "Comfort-Oriented Design",
          "Good Visibility",
          "Superior Handling",
        ],
        description:
          "The Tata LPK 2518 is designed for heavy loads and challenging terrains. Its robust frame structure and powerful engine ensure exceptional performance, while the comfortable cabin provides excellent visibility and handling for the driver.",
        image: "https://patelsanghmp.com/uploads/e4a6303d-b762-4a2c-8c2d-f46873355e9f.png",
      },
      {
        name: "Bharat Benz 1617",
        manufacture_year: 2023,
        brand: "Bharat Benz",
        model: "1617",
        area: "India",
        height: "90 inches",
        width: "88 inches",
        length: "200 inches",
        weight: "9000 kg",
        cost: 2000000,
        wheel_count: 6,
        payload_capacity: "16000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "170 HP",
          power: "170 HP",
          torque: "600 Nm",
        },
        transmission: "Manual",
        features: [
          "High Fuel Efficiency",
          "Comfortable Cabin",
          "Easy Maintenance",
          "Durable Design",
        ],
        description:
          "The Bharat Benz 1617 is engineered for efficiency and durability. It offers high fuel efficiency and a comfortable cabin for long journeys. With easy maintenance features and a robust design, it is well-suited for varied transportation needs.",
        image: "https://patelsanghmp.com/uploads/01a33100-d91d-4427-9c22-9907daf07375.png",
      },
      {
        name: "Force Traveller",
        manufacture_year: 2022,
        brand: "Force",
        model: "Traveller",
        area: "India",
        height: "85 inches",
        width: "80 inches",
        length: "200 inches",
        weight: "5000 kg",
        cost: 1300000,
        wheel_count: 6,
        payload_capacity: "1500 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "120 HP",
          power: "120 HP",
          torque: "350 Nm",
        },
        transmission: "Manual",
        features: [
          "Spacious Seating",
          "Air Conditioning",
          "Power Steering",
          "Excellent Stability",
        ],
        description:
          "The Force Traveller is ideal for passenger transport with spacious seating and comfort features. Its reliable engine and excellent stability make it perfect for both city commutes and long-distance travel. Enhanced with air conditioning and power steering, it ensures a smooth ride.",
        image: "https://patelsanghmp.com/uploads/9151666d-4f68-42b6-99a5-929f3c496bfe.png",
      },
      {
        name: "Isuzu NPR 400",
        manufacture_year: 2023,
        brand: "Isuzu",
        model: "NPR 400",
        area: "India",
        height: "95 inches",
        width: "90 inches",
        length: "210 inches",
        weight: "11000 kg",
        cost: 2400000,
        wheel_count: 6,
        payload_capacity: "20000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "190 HP",
          power: "190 HP",
          torque: "650 Nm",
        },
        transmission: "Manual",
        features: [
          "Advanced Safety Features",
          "Comfortable Driving Position",
          "Low Operating Costs",
          "Robust Design",
        ],
        description:
          "The Isuzu NPR 400 is a powerful medium truck built for performance and safety. With advanced safety features and a comfortable driving position, it provides a smooth driving experience. Its robust design ensures reliability for various transportation needs.",
        image: "https://patelsanghmp.com/uploads/eb7aab67-7160-4adb-89ab-daa15de32eec.png",
      },
    ],
  },
  {
    category: "Heavy Trucks",
    trucks: [
      {
        name: "Tata 1212 LPK",
        manufacture_year: 2021,
        brand: "Tata",
        model: "1212 LPK",
        area: "India",
        height: "110 inches",
        width: "90 inches",
        length: "270 inches",
        weight: "8000 kg",
        cost: 2800000,
        wheel_count: 10,
        payload_capacity: "12000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "3786 cc",
          power: "130 HP",
          torque: "400 Nm",
        },
        transmission: "Manual",
        features: [
          "Heavy-Duty Chassis",
          "High Load Capacity",
          "Comfortable Cabin",
          "Reliable Performance",
        ],
        description:
          "The Tata 1212 LPK is designed for heavy-duty applications, providing exceptional reliability and performance for transporting goods over long distances. Its robust construction ensures durability under tough conditions.",
        image: "https://patelsanghmp.com/uploads/92d6311f-1e07-4ffa-b480-4d11266756f4.png",
      },
      {
        name: "Ashok Leyland 2518",
        manufacture_year: 2022,
        brand: "Ashok Leyland",
        model: "2518",
        area: "India",
        height: "105 inches",
        width: "92 inches",
        length: "290 inches",
        weight: "9000 kg",
        cost: 3200000,
        wheel_count: 10,
        payload_capacity: "20000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "5660 cc",
          power: "180 HP",
          torque: "600 Nm",
        },
        transmission: "Manual",
        features: [
          "High Stability",
          "Comfortable Driving Experience",
          "Advanced Safety Features",
          "Fuel Efficient",
        ],
        description:
          "The Ashok Leyland 2518 offers high stability and a comfortable driving experience. With a powerful engine and advanced safety features, it's perfect for transporting heavy loads across diverse terrains.",
        image: "https://patelsanghmp.com/uploads/ceb46f56-762b-4676-b321-d450e3893ead.png",
      },
      {
        name: "Bharat Benz 3123",
        manufacture_year: 2023,
        brand: "Bharat Benz",
        model: "3123",
        area: "India",
        height: "110 inches",
        width: "94 inches",
        length: "290 inches",
        weight: "9500 kg",
        cost: 3500000,
        wheel_count: 10,
        payload_capacity: "23000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "3907 cc",
          power: "230 HP",
          torque: "850 Nm",
        },
        transmission: "Manual",
        features: [
          "High Load Capacity",
          "Powerful Engine",
          "Comfortable Cabin",
          "Robust Build Quality",
        ],
        description:
          "The Bharat Benz 3123 is built for heavy-duty transportation, featuring a powerful engine and a robust build quality. Its spacious cabin ensures comfort during long drives.",
        image: "https://patelsanghmp.com/uploads/f6863196-63a9-4196-ac9a-fcd3dd1693b7.png",
      },
      {
        name: "Mahindra Heavy Trucks",
        manufacture_year: 2022,
        brand: "Mahindra",
        model: "M7",
        area: "India",
        height: "112 inches",
        width: "96 inches",
        length: "300 inches",
        weight: "10000 kg",
        cost: 4000000,
        wheel_count: 10,
        payload_capacity: "25000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "6800 cc",
          power: "250 HP",
          torque: "900 Nm",
        },
        transmission: "Manual",
        features: [
          "Durable Design",
          "High Power Output",
          "Comfortable and Spacious Cabin",
          "Enhanced Stability",
        ],
        description:
          "The Mahindra M7 heavy truck is engineered for superior power and stability, making it suitable for heavy-duty applications. Its spacious cabin ensures driver comfort during long hauls.",
        image: "https://patelsanghmp.com/uploads/ac3903f4-38c3-400a-87ba-6e2d9f745212.png",
      },
      {
        name: "Volvo FH 460",
        manufacture_year: 2023,
        brand: "Volvo",
        model: "FH 460",
        area: "India",
        height: "130 inches",
        width: "98 inches",
        length: "300 inches",
        weight: "12000 kg",
        cost: 7500000,
        wheel_count: 10,
        payload_capacity: "30000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "13000 cc",
          power: "460 HP",
          torque: "2300 Nm",
        },
        transmission: "Automatic",
        features: [
          "Exceptional Performance",
          "Advanced Safety Technology",
          "Comfortable Driver Environment",
          "Fuel Efficiency",
        ],
        description:
          "The Volvo FH 460 is a premium heavy truck designed for long-haul operations, featuring advanced safety technology and exceptional fuel efficiency. Its comfortable cabin is designed for the ultimate driving experience.",
        image: "https://patelsanghmp.com/uploads/902fab5c-26fe-4855-8826-d23f920cc32d.png",
      },
      {
        name: "Scania R 500",
        manufacture_year: 2022,
        brand: "Scania",
        model: "R 500",
        area: "India",
        height: "130 inches",
        width: "96 inches",
        length: "300 inches",
        weight: "12500 kg",
        cost: 8000000,
        wheel_count: 10,
        payload_capacity: "35000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "13000 cc",
          power: "500 HP",
          torque: "2500 Nm",
        },
        transmission: "Automatic",
        features: [
          "Powerful Performance",
          "Comfortable Cabin",
          "Advanced Connectivity Features",
          "High Fuel Efficiency",
        ],
        description:
          "The Scania R 500 combines power and efficiency, making it ideal for heavy-duty transport. Its advanced connectivity features enhance the driving experience, while the spacious cabin ensures comfort.",
        image: "https://patelsanghmp.com/uploads/143a7a3e-c8d1-411e-9e45-8deb1b850899.png",
      },
      {
        name: "Isuzu Giga",
        manufacture_year: 2023,
        brand: "Isuzu",
        model: "Giga",
        area: "India",
        height: "120 inches",
        width: "95 inches",
        length: "300 inches",
        weight: "11500 kg",
        cost: 4500000,
        wheel_count: 10,
        payload_capacity: "28000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "6000 cc",
          power: "280 HP",
          torque: "1000 Nm",
        },
        transmission: "Manual",
        features: [
          "High Payload Capacity",
          "Robust Performance",
          "Comfortable Cabin",
          "Enhanced Stability",
        ],
        description:
          "The Isuzu Giga is built for heavy-duty tasks, providing a high payload capacity and robust performance. Its comfortable cabin and stability features make it suitable for long-distance hauls.",
        image: "https://patelsanghmp.com/uploads/8eb59cb2-6fe9-4ceb-aa44-461c14392b1a.png",
      },
      {
        name: "MAN CLA 25.400",
        manufacture_year: 2022,
        brand: "MAN",
        model: "CLA 25.400",
        area: "India",
        height: "115 inches",
        width: "97 inches",
        length: "300 inches",
        weight: "12000 kg",
        cost: 6000000,
        wheel_count: 10,
        payload_capacity: "40000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "8000 cc",
          power: "400 HP",
          torque: "1600 Nm",
        },
        transmission: "Automatic",
        features: [
          "Heavy Load Capability",
          "Comfortable Cabin",
          "Advanced Safety Features",
          "Efficient Fuel Consumption",
        ],
        description:
          "The MAN CLA 25.400 is designed for heavy load capability, offering a powerful engine and a comfortable cabin. Its advanced safety features ensure secure transportation of goods.",
        image: "https://patelsanghmp.com/uploads/9f15259e-ac34-4000-8ba7-ff2a538ba7c6.png",
      },
    ],
  },
  {
    category: "Tippers",
    trucks: [
      {
        name: "Ashok Leyland 2518i",
        manufacture_year: 2021,
        brand: "Ashok Leyland",
        model: "2518i",
        area: "India",
        height: "100 inches",
        width: "90 inches",
        length: "300 inches",
        weight: "9000 kg",
        cost: 3200000,
        wheel_count: 10,
        payload_capacity: "15000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "5660 cc",
          power: "180 HP",
          torque: "600 Nm",
        },
        transmission: "Manual",
        features: [
          "High Payload Capacity",
          "Robust Construction",
          "Comfortable Cabin",
          "Efficient Fuel Consumption",
        ],
        description:
          "The Ashok Leyland 2518i is a robust tipper designed for heavy-duty applications, offering high payload capacity and reliable performance in construction and mining operations.",
        image: "https://patelsanghmp.com/uploads/e4bbcf51-ad24-41bd-b093-ce8d4897ab42.png",
      },
      {
        name: "Tata 2518 Tipper",
        manufacture_year: 2022,
        brand: "Tata",
        model: "2518 Tipper",
        area: "India",
        height: "100 inches",
        width: "91 inches",
        length: "300 inches",
        weight: "9500 kg",
        cost: 3500000,
        wheel_count: 10,
        payload_capacity: "18000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "5660 cc",
          power: "180 HP",
          torque: "600 Nm",
        },
        transmission: "Manual",
        features: [
          "Heavy-Duty Build",
          "High Ground Clearance",
          "Spacious Cabin",
          "Powerful Performance",
        ],
        description:
          "The Tata 2518 Tipper is built for tough conditions, providing a high ground clearance and powerful performance ideal for construction and heavy material transport.",
        image: "https://patelsanghmp.com/uploads/11ee1699-3231-4d55-979e-184e524f1001.png",
      },
      {
        name: "Mahindra Tipper",
        manufacture_year: 2022,
        brand: "Mahindra",
        model: "M2",
        area: "India",
        height: "95 inches",
        width: "88 inches",
        length: "290 inches",
        weight: "9000 kg",
        cost: 2800000,
        wheel_count: 10,
        payload_capacity: "16000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "2730 cc",
          power: "100 HP",
          torque: "300 Nm",
        },
        transmission: "Manual",
        features: [
          "Compact Design",
          "Robust Performance",
          "Durable Chassis",
          "Easy Maneuverability",
        ],
        description:
          "The Mahindra M2 Tipper is designed for efficiency and reliability, providing excellent maneuverability and a robust build suitable for various terrains.",
        image: "https://patelsanghmp.com/uploads/30b6624f-7e08-4fb2-8a86-48574be9a537.png",
      },
      {
        name: "Eicher Pro 6025HT",
        manufacture_year: 2023,
        brand: "Eicher",
        model: "Pro 6025HT",
        area: "India",
        height: "110 inches",
        width: "92 inches",
        length: "300 inches",
        weight: "9500 kg",
        cost: 4000000,
        wheel_count: 10,
        payload_capacity: "25000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "4000 cc",
          power: "250 HP",
          torque: "900 Nm",
        },
        transmission: "Manual",
        features: [
          "Heavy Load Capacity",
          "Comfortable Cabin",
          "Strong Performance",
          "Advanced Safety Features",
        ],
        description:
          "The Eicher Pro 6025HT is designed for heavy-duty tipping applications, featuring a powerful engine and robust build for superior performance in construction and mining.",
        image: "https://patelsanghmp.com/uploads/ee81b666-8a65-462e-b7af-1949f82ba0a9.png",
      },
      {
        name: "Bharat Benz 1623 Tipper",
        manufacture_year: 2022,
        brand: "Bharat Benz",
        model: "1623",
        area: "India",
        height: "110 inches",
        width: "94 inches",
        length: "300 inches",
        weight: "10000 kg",
        cost: 3700000,
        wheel_count: 10,
        payload_capacity: "20000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "3907 cc",
          power: "230 HP",
          torque: "850 Nm",
        },
        transmission: "Manual",
        features: [
          "High Payload Capacity",
          "Durable Design",
          "Comfortable Cabin",
          "Fuel Efficient",
        ],
        description:
          "The Bharat Benz 1623 Tipper combines strength and efficiency, designed to handle tough tasks with ease while providing driver comfort and safety.",
        image: "https://patelsanghmp.com/uploads/a2ab62d7-414e-43ce-aae3-3e1e8ddd797d.png",
      },
      {
        name: "Force 12 Tipper",
        manufacture_year: 2023,
        brand: "Force",
        model: "12 Tipper",
        area: "India",
        height: "90 inches",
        width: "85 inches",
        length: "260 inches",
        weight: "8500 kg",
        cost: 2200000,
        wheel_count: 10,
        payload_capacity: "12000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "2596 cc",
          power: "90 HP",
          torque: "230 Nm",
        },
        transmission: "Manual",
        features: [
          "Compact Design",
          "High Load Capacity",
          "Comfortable Cabin",
          "Robust Performance",
        ],
        description:
          "The Force 12 Tipper is ideal for urban construction tasks, providing a compact design for easy maneuverability while ensuring high load capacity.",
        image: "https://patelsanghmp.com/uploads/9bf79ecf-dc4c-46c5-a0e3-ae3dcbe346b6.png",
      },
      {
        name: "Isuzu Tipper",
        manufacture_year: 2022,
        brand: "Isuzu",
        model: "Giga Tipper",
        area: "India",
        height: "110 inches",
        width: "95 inches",
        length: "300 inches",
        weight: "12000 kg",
        cost: 4500000,
        wheel_count: 10,
        payload_capacity: "28000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "6000 cc",
          power: "280 HP",
          torque: "1000 Nm",
        },
        transmission: "Manual",
        features: [
          "High Performance",
          "Durable Build",
          "Spacious Cabin",
          "Efficient Fuel Consumption",
        ],
        description:
          "The Isuzu Giga Tipper is built for high performance and durability, making it suitable for heavy-duty transport and construction projects.",
        image: "https://patelsanghmp.com/uploads/9ed2099a-fd89-420f-bcee-f7a6018f80a4.png",
      },
      {
        name: "Tata LPK 2518 Tipper",
        manufacture_year: 2023,
        brand: "Tata",
        model: "LPK 2518 Tipper",
        area: "India",
        height: "100 inches",
        width: "90 inches",
        length: "290 inches",
        weight: "9500 kg",
        cost: 3600000,
        wheel_count: 10,
        payload_capacity: "20000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "5660 cc",
          power: "180 HP",
          torque: "600 Nm",
        },
        transmission: "Manual",
        features: [
          "Reliable Performance",
          "Comfortable Cabin",
          "High Ground Clearance",
          "Advanced Safety Features",
        ],
        description:
          "The Tata LPK 2518 Tipper is designed for reliable performance in construction and mining, featuring a comfortable cabin and high ground clearance for rough terrains.",
        image: "https://patelsanghmp.com/uploads/e2b9944b-59cf-4444-9768-23a2540555c9.png",
      },
    ],
  },
  {
    category: "Refrigerated Trucks",
    trucks: [
      {
        name: "Tata Ultra 912",
        manufacture_year: 2022,
        brand: "Tata",
        model: "Ultra 912",
        area: "India",
        height: "105 inches",
        width: "85 inches",
        length: "300 inches",
        weight: "6000 kg",
        cost: 2200000,
        wheel_count: 6,
        payload_capacity: "5000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "2956 cc",
          power: "120 HP",
          torque: "400 Nm",
        },
        transmission: "Manual",
        features: [
          "Efficient Refrigeration System",
          "Spacious Cargo Area",
          "Reliable Performance",
          "Good Fuel Efficiency",
        ],
        description:
          "The Tata Ultra 912 is designed for transporting perishable goods, featuring a powerful refrigeration system and spacious cargo area for maximum efficiency.",
        image: "https://example.com/images/tata_ultra_912.jpg",
      },
      {
        name: "Mahindra Refrigerated Van",
        manufacture_year: 2022,
        brand: "Mahindra",
        model: "Refrigerated Van",
        area: "India",
        height: "90 inches",
        width: "80 inches",
        length: "250 inches",
        weight: "5000 kg",
        cost: 1800000,
        wheel_count: 6,
        payload_capacity: "4000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "2498 cc",
          power: "100 HP",
          torque: "300 Nm",
        },
        transmission: "Manual",
        features: [
          "Compact Design",
          "Reliable Refrigeration",
          "Good Maneuverability",
          "User-Friendly Controls",
        ],
        description:
          "The Mahindra Refrigerated Van is ideal for urban deliveries, featuring a compact design that ensures easy maneuverability while maintaining optimal temperatures for perishable goods.",
        image: "https://example.com/images/mahindra_refrigerated_van.jpg",
      },
      {
        name: "Isuzu NPR 400 Reefer",
        manufacture_year: 2023,
        brand: "Isuzu",
        model: "NPR 400",
        area: "India",
        height: "95 inches",
        width: "85 inches",
        length: "290 inches",
        weight: "6500 kg",
        cost: 2500000,
        wheel_count: 6,
        payload_capacity: "5000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "2999 cc",
          power: "150 HP",
          torque: "420 Nm",
        },
        transmission: "Manual",
        features: [
          "Efficient Cooling System",
          "Durable Build Quality",
          "Spacious Interior",
          "High Payload Capacity",
        ],
        description:
          "The Isuzu NPR 400 Reefer offers excellent cooling capabilities and a spacious interior, making it suitable for transporting temperature-sensitive goods across long distances.",
        image: "https://example.com/images/isuzu_npr_400_reefer.jpg",
      },
      {
        name: "Eicher Pro 1110",
        manufacture_year: 2022,
        brand: "Eicher",
        model: "Pro 1110",
        area: "India",
        height: "98 inches",
        width: "90 inches",
        length: "290 inches",
        weight: "6500 kg",
        cost: 2000000,
        wheel_count: 6,
        payload_capacity: "5000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "3300 cc",
          power: "150 HP",
          torque: "500 Nm",
        },
        transmission: "Manual",
        features: [
          "Advanced Refrigeration Technology",
          "Robust Chassis",
          "Comfortable Cabin",
          "Fuel Efficient",
        ],
        description:
          "The Eicher Pro 1110 is built for heavy-duty refrigerated transport, providing a robust chassis and advanced refrigeration technology for optimal performance.",
        image: "https://example.com/images/eicher_pro_1110.jpg",
      },
      {
        name: "Ashok Leyland Refrigerated Truck",
        manufacture_year: 2022,
        brand: "Ashok Leyland",
        model: "Refrigerated Truck",
        area: "India",
        height: "105 inches",
        width: "90 inches",
        length: "300 inches",
        weight: "7000 kg",
        cost: 2300000,
        wheel_count: 6,
        payload_capacity: "6000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "3660 cc",
          power: "160 HP",
          torque: "600 Nm",
        },
        transmission: "Manual",
        features: [
          "High-Performance Refrigeration",
          "Spacious Cargo Capacity",
          "User-Friendly Controls",
          "Durable Design",
        ],
        description:
          "The Ashok Leyland Refrigerated Truck is designed for transporting large quantities of perishable goods, featuring high-performance refrigeration and a spacious cargo area.",
        image: "https://example.com/images/ashok_leyland_refrigerated.jpg",
      },
      {
        name: "Force Trax Refrigerated Van",
        manufacture_year: 2023,
        brand: "Force",
        model: "Trax Refrigerated",
        area: "India",
        height: "85 inches",
        width: "80 inches",
        length: "230 inches",
        weight: "4000 kg",
        cost: 1600000,
        wheel_count: 6,
        payload_capacity: "4000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "2596 cc",
          power: "90 HP",
          torque: "230 Nm",
        },
        transmission: "Manual",
        features: [
          "Compact Size",
          "Reliable Refrigeration System",
          "Efficient Fuel Usage",
          "Good Payload Capacity",
        ],
        description:
          "The Force Trax Refrigerated Van is compact and efficient, ideal for delivering perishable goods in urban environments while maintaining optimal temperatures.",
        image: "https://example.com/images/force_trax_refrigerated.jpg",
      },
      {
        name: "Tata LPT 1613 Reefer",
        manufacture_year: 2023,
        brand: "Tata",
        model: "LPT 1613",
        area: "India",
        height: "100 inches",
        width: "90 inches",
        length: "310 inches",
        weight: "8000 kg",
        cost: 2800000,
        wheel_count: 6,
        payload_capacity: "7000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "5660 cc",
          power: "180 HP",
          torque: "600 Nm",
        },
        transmission: "Manual",
        features: [
          "Spacious Cargo Area",
          "Advanced Refrigeration",
          "High Payload Capacity",
          "Robust Build Quality",
        ],
        description:
          "The Tata LPT 1613 Reefer is designed for heavy-duty refrigerated transport, featuring a spacious cargo area and advanced refrigeration technology for perishable goods.",
        image: "https://example.com/images/tata_lpt_1613_reefer.jpg",
      },
      {
        name: "Bharat Benz 3123 Refrigerated",
        manufacture_year: 2023,
        brand: "Bharat Benz",
        model: "3123",
        area: "India",
        height: "110 inches",
        width: "95 inches",
        length: "320 inches",
        weight: "8500 kg",
        cost: 3500000,
        wheel_count: 10,
        payload_capacity: "8000 kg",
        fuel_type: "Diesel",
        engine: {
          type: "Diesel",
          displacement: "3907 cc",
          power: "230 HP",
          torque: "850 Nm",
        },
        transmission: "Manual",
        features: [
          "Superior Cooling Performance",
          "Robust Construction",
          "Spacious Cabin",
          "Advanced Safety Features",
        ],
        description:
          "The Bharat Benz 3123 Refrigerated truck offers superior cooling performance and robust construction, perfect for long-distance transport of temperature-sensitive products.",
        image: "https://example.com/images/bharat_benz_3123_refrigerated.jpg",
      },
    ],
  },
];

export const testimonialData = [
  {
    text: "I recently purchased a mini truck from this site, and the process was seamless. The truck was in great condition, and the sales team walked me through every step of the transaction. I’m very happy with my purchase and will definitely be back for future buys.",
    author: "Rajesh Kumar",
    position: "Small Business Owner",
    avatarUrl: "https://randomuser.me/api/portraits/men/2.jpg",
  },
  {
    text: "Selling my old truck was such an easy experience. The team made the entire process fast and hassle-free. They gave me a fair price and handled all the paperwork. I’ll be recommending this service to all my friends in the trucking industry.",
    author: "Priya Sharma",
    position: "Fleet Operator",
    avatarUrl: "https://randomuser.me/api/portraits/women/2.jpg",
  },
  {
    text: "As a first-time buyer of a tripper bus, I was nervous, but the staff made the whole experience straightforward. They helped me choose the best model for my needs and provided excellent after-sale support. Highly recommend for anyone looking for buses or trucks!",
    author: "Arvind Patel",
    position: "Event Planner",
    avatarUrl: "https://randomuser.me/api/portraits/men/3.jpg",
  },
  {
    text: "With over 15 years in the transportation business, I’ve bought and sold many vehicles, but this place stands out. Their selection of trucks and buses is impressive, and the customer service is top-tier. Always reliable and competitive prices.",
    author: "Sangeeta Reddy",
    position: "Fleet Manager",
    avatarUrl: "https://randomuser.me/api/portraits/women/3.jpg",
  },
  {
    text: "I sold my mini truck through this service, and I was pleasantly surprised by how quick and smooth the process was. They offered a fair price and made the paperwork easy to handle. If you're looking to sell your vehicle, this is the place to go.",
    author: "Vikram Singh",
    position: "Entrepreneur",
    avatarUrl: "https://randomuser.me/api/portraits/men/4.jpg",
  },
  {
    text: "I was searching for a used tripper bus for my growing transportation company, and I found exactly what I needed here. The staff was incredibly knowledgeable and helped me navigate all the options. I couldn’t be happier with the purchase and the service.",
    author: "Neha Desai",
    position: "Transport Company Owner",
    avatarUrl: "https://randomuser.me/api/portraits/women/4.jpg",
  },
];
