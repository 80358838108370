// import React, { useState, useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom";

// const SeachComponent = () => {
//   const [selectedType, setSelectedType] = useState("Buses");
//   const [vehicleType, setVehicleType] = useState("");
//   const [brand, setBrand] = useState("");
//   const [model, setModel] = useState("");
//   const [availableModels, setAvailableModels] = useState([]);
//   const [availableBrands, setAvailableBrands] = useState([]);
//   const navigate = useNavigate();

//   // Fetch available brands based on selected vehicle type
//   useEffect(() => {
//     const fetchBrands = async () => {
//       try {
//         const response = await fetch(`/api/brands?category=${vehicleType}`);
//         if (!response.ok) {
//           throw new Error("Failed to fetch brands");
//         }
//         const data = await response.json();
//         setAvailableBrands(data); // Set available brands
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     if (vehicleType) {
//       fetchBrands();
//     }
//   }, [vehicleType]);

//   // Fetch available models when a brand is selected
//   useEffect(() => {
//     const fetchModels = async () => {
//       if (brand && vehicleType) {
//         try {
//           const response = await fetch(
//             `/api/models?category=${vehicleType}&brand=${brand}`
//           );
//           if (!response.ok) {
//             throw new Error("Failed to fetch models");
//           }
//           const data = await response.json();
//           setAvailableModels(data); // Set available models
//         } catch (error) {
//           console.error(error);
//         }
//       }
//     };

//     fetchModels();
//   }, [brand, vehicleType]);

//   const handleSearch = () => {
//     navigate(`/search?category=${vehicleType}&brand=${brand}`);
//   };

//   return (
//     <div className="bg-gray-800 p-8 w-full max-w-7xl mx-auto mt-10 rounded-lg shadow-lg">
//       <h1 className="text-3xl text-white font-bold mb-6">
//         Search for Vehicles
//       </h1>
//       <div className="flex gap-4 mb-6">
//         <button
//           className={`py-2 px-6 rounded-full text-lg font-semibold transition ${
//             selectedType === "Trucks"
//               ? "bg-red-600 text-white"
//               : "bg-white text-gray-800 border-2 border-red-600"
//           }`}
//           onClick={() => {
//             setSelectedType("Trucks");
//             setVehicleType("");
//             setBrand("");
//             setModel("");
//           }}
//         >
//           Used
//         </button>
//         <button
//           className={`py-2 px-6 rounded-full text-lg font-semibold transition ${
//             selectedType === "Buses"
//               ? "bg-red-600 text-white"
//               : "bg-white text-gray-800 border-2 border-red-600"
//           }`}
//           onClick={() => {
//             setSelectedType("Buses");
//             setVehicleType("");
//             setBrand("");
//             setModel("");
//           }}
//         >
//           New
//         </button>
//       </div>

//       <div className="bg-white p-6 rounded-lg shadow-md">
//         <fieldset className="mb-6">
//           <legend className="text-lg font-semibold mb-4">
//             Select Vehicle Type
//           </legend>
//           <div className="flex gap-8">
//             <label className="flex items-center text-gray-700">
//               <input
//                 type="radio"
//                 name="vehicle"
//                 value="Truck"
//                 checked={vehicleType === "Truck"}
//                 onChange={(e) => setVehicleType(e.target.value)}
//                 className="mr-2"
//               />
//               Truck
//             </label>
//             <label className="flex items-center text-gray-700">
//               <input
//                 type="radio"
//                 name="vehicle"
//                 value="Bus"
//                 checked={vehicleType === "Bus"}
//                 onChange={(e) => setVehicleType(e.target.value)}
//                 className="mr-2"
//               />
//               Bus
//             </label>
//             <label className="flex items-center text-gray-700">
//               <input
//                 type="radio"
//                 name="vehicle"
//                 value="Tippers"
//                 checked={vehicleType === "Tippers"}
//                 onChange={(e) => setVehicleType(e.target.value)}
//                 className="mr-2"
//               />
//               Trippers
//             </label>
//             <label className="flex items-center text-gray-700">
//               <input
//                 type="radio"
//                 name="vehicle"
//                 value="Light Trucks"
//                 checked={vehicleType === "Light Trucks"}
//                 onChange={(e) => setVehicleType(e.target.value)}
//                 className="mr-2"
//               />
//               Mini Truck
//             </label>
//           </div>

//         </fieldset>

//         <div className="flex gap-4 mb-6">
//           <CustomDropdown
//             options={availableBrands}
//             selectedOption={brand}
//             onSelect={(selected) => setBrand(selected)}
//             placeholder="-- Select Brand --"
//           />

//           <CustomDropdown
//             options={availableModels}
//             selectedOption={model}
//             onSelect={(selected) => setModel(selected)}
//             placeholder="-- Select Model --"
//           />

//           <button
//             className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-red-700 transition w-40"
//             onClick={handleSearch}
//           >
//             Search
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// const CustomDropdown = ({ options, selectedOption, onSelect, placeholder }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const dropdownRef = useRef(null);

//   const handleOptionClick = (option) => {
//     onSelect(option);
//     setIsOpen(false);
//   };

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     };
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   return (
//     <div className="relative w-64" ref={dropdownRef}>
//       <button
//         className="p-3 border border-gray-300 rounded-lg w-full text-left"
//         onClick={toggleDropdown}
//       >
//         {selectedOption || placeholder}
//       </button>
//       {isOpen && (
//         <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-lg max-h-48 overflow-y-auto shadow-lg z-10">
//           {options.map((option, index) => (
//             <div
//               key={index}
//               className="p-2 hover:bg-gray-200 cursor-pointer"
//               onClick={() => handleOptionClick(option)}
//             >
//               {option}
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default SeachComponent;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Truck, Bus, Package, TruckIcon, Search } from "lucide-react";
import VehicleTypeSelector from "./VehicleTypeSelector";
import CustomDropdown from "./CustomDropdown";
import SearchHeader from "./SearchHeader";

const SearchComponent = () => {
  const [selectedType, setSelectedType] = useState("new");
  const [availableStates, setavailableStates] = useState([]);
  const [vehicleType, setVehicleType] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [availableModels, setAvailableModels] = useState([]);
  const [availableBrands, setAvailableBrands] = useState([]);
  const [state, setState] = useState("");
  const navigate = useNavigate();

  console.log(state);
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(
          `/api/brands?category=${vehicleType}`
        );
        if (!response.ok) throw new Error("Failed to fetch brands");
        const data = await response.json();
        setAvailableBrands(data);
      } catch (error) {
        console.error(error);
        setAvailableBrands([]);
      }
    };

    if (vehicleType) fetchBrands();
  }, [vehicleType]);

  useEffect(() => {
    const fetchStates = async () => {
      if (selectedType === "State") {
        try {
          const response = await fetch(
            "/api/locations"
          );
          if (!response.ok) throw new Error("Failed to fetch states");
          const data = await response.json();
          setavailableStates(data);
        } catch (error) {
          console.error(error);
          setavailableStates([]); // Set empty array if the fetch fails
        }
      }
    };

    fetchStates(); // Call the async function
  }, [selectedType]);

  useEffect(() => {
    const fetchModels = async () => {
      if (brand && vehicleType) {
        try {
          const response = await fetch(
            `/api/models?category=${vehicleType}&brand=${brand}`
          );
          if (!response.ok) throw new Error("Failed to fetch models");
          const data = await response.json();
          setAvailableModels(data);
        } catch (error) {
          console.error(error);
          setAvailableModels([]);
        }
      }
    };

    fetchModels();
  }, [brand, vehicleType]);

  const handleSearch = () => {
    navigate(`/search?category=${vehicleType}&brand=${brand}&state=${state}`);
  };

  const resetSelections = (type) => {
    setSelectedType(type);
    setVehicleType("");
    setBrand("");
    setModel("");
  };

  const vehicleTypes = [
    { id: "Truck", label: "Truck", icon: <Truck className="w-5 h-5" /> },
    { id: "Bus", label: "Bus", icon: <Bus className="w-5 h-5" /> },
    {
      id: "Tippers",
      label: "Tripper",
      icon: <TruckIcon className="w-5 h-5" />,
    },
    {
      id: "Light Trucks",
      label: "Mini Truck",
      icon: <Package className="w-5 h-5" />,
    },
  ];

  return (
    <div className="bg-gray-50" id="searchvehicals">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 my-4">
        <SearchHeader />

        <div className="mt-8 bg-white rounded-2xl p-4 sm:p-6 lg:p-8 shadow-lg w-full  mx-auto">
          {/* Vehicle Condition Selector */}
          <div className="flex gap-2 lg:gap-4 mb-8 justify-center sm:justify-start">
            {["new", "used", "State"].map((type) => (
              <button
                key={type}
                onClick={() => resetSelections(type)}
                className={`
        px-6 py-3 text-sm sm:px-10 sm:py-4 sm:text-lg rounded-full font-medium transition-all duration-300
        ${
          selectedType === type
            ? "bg-[#103c64] text-white shadow-lg transform scale-105"
            : "bg-gray-100 text-gray-600 hover:bg-gray-200"
        }
      `}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </button>
            ))}
          </div>

          {/* Vehicle Type Selector */}
          <VehicleTypeSelector
            types={vehicleTypes}
            selected={vehicleType}
            onChange={setVehicleType}
          />

          {/* Dropdowns and Search Button */}
          {selectedType !== "State" ? (
            <div className="mt-8 flex flex-wrap gap-4 justify-between">
              <CustomDropdown
                options={availableBrands}
                selected={brand}
                onSelect={setBrand}
                placeholder="Select Brand"
                className="flex-1 min-w-[200px] sm:min-w-[250px]"
              />

              <CustomDropdown
                options={availableModels}
                selected={model}
                onSelect={setModel}
                placeholder="Select Model"
                className="flex-1 min-w-[200px] sm:min-w-[250px]"
              />

              <button
                onClick={handleSearch}
                className="flex items-center justify-center gap-2 sm:gap-3 bg-[#103c64] text-white px-4 py-2 sm:px-8 sm:py-4 rounded-xl
  hover:bg-red-600 transition-all duration-300 shadow-md transform hover:scale-105 active:scale-95"
              >
                <Search className="w-5 h-5" />
                <span className="text-sm sm:text-lg">Search</span>
              </button>
            </div>
          ) : (
            <div className="mt-8 flex flex-wrap gap-4 justify-between">
              <CustomDropdown
                options={availableStates}
                selected={state}
                onSelect={setState}
                placeholder="Select State"
                className="flex-1 min-w-[200px] sm:min-w-[250px]"
              />

              <button
                onClick={handleSearch}
                className="flex items-center justify-center gap-2 sm:gap-3 bg-red-500 text-white px-4 py-2 sm:px-8 sm:py-4 rounded-xl
  hover:bg-red-600 transition-all duration-300 shadow-md transform hover:scale-105 active:scale-95"
              >
                <Search className="w-5 h-5" />
                <span className="text-sm sm:text-lg">Search</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
