import React from "react";
import { Calendar, MapPinHouse } from "lucide-react";
import { useTranslation } from "react-i18next";

export default function RegistrationSection({ registration }) {
  const { t } = useTranslation();
  if (!registration) return null;

  const validityItems = [
    { label: "City", value: registration[1] },
    { label: "State", value: registration[0] },
  ].filter((item) => item.value);

  if (!validityItems.length) return null;

  return (
    <div className="mt-8">
      <h2 className="text-lg font-semibold mb-4">{t("Registration Details")}</h2>
      <div className="grid grid-cols-2 gap-4">
        {validityItems.map((item, index) => (
          <div key={index} className="flex items-center space-x-3">
            <div className="p-2 bg-green-50 rounded-lg text-green-600">
              <MapPinHouse className="w-5 h-5" />
            </div>
            <div>
              <div className="text-sm text-gray-500">{item.label}</div>
              <div className="font-medium text-gray-900">{item.value}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
