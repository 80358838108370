import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      vehicles: "Vehicles",
      category: "Category",
      name: "Name",
      specifications: "Specifications",
      dimensions: "Dimensions",
      length: "Length",
      width: "Width",
      height: "Height",
      validities: "Validities",
      description: "Description",
      contactSeller: "Contact Seller",
      year: "Year",
      weight: "Weight",
      payload: "Payload",
      fuelType: "Fuel Type",
      transmission: "Transmission",
      displacement: "Displacement",
      power: "Power",
      torque: "Torque",
      wheels: "Wheels",
      wheelHealth: "Wheel Health",
      airConditioner: "Air Conditioner",
      ownersTillDate: "Owners Till Date",
      hypothecation: "Hypothecation",
      location: "Location",
      Dimensions: "Dimensions",
      Specifications: "Specifications",
      Length: "Length",
      Width: "Width",
      Height: "Height",
      Insurance: "Insurance",
      Permit: "Permit",
      Tax: "Tax",
      Fitness: "Fitness",
      Validity: "Validity Details",
      diesel: "Diesel",
      petrol: "Petrol",
      automatic: "Automatic",
      manual: "Manual",
      contact: "Contact Seller",
      "Document Status": "Document Status",
      "RC Document": "RC Document",
      "Aadhaar Document": "Aadhaar Document",
      Available: "Available",
      "Not Available": "Not Available",
      "Contact Seller": "Contact Seller",
      "Full Name": "Full Name",
      "Phone Number": "Phone Number",
      "Email Address": "Email Address",
      Address: "Address",
      Message: "Message",
      Cancel: "Cancel",
      "Send Inquiry": "Send Inquiry",
      "Error submitting inquiry": "Error submitting inquiry",
      "Network error, please try again later.":
        "Network error, please try again later.",
    },
  },
  hi: {
    translation: {
      vehicles: "वाहन",
      category: "श्रेणी",
      name: "नाम",
      specifications: "विशेषताएं",
      dimensions: "आयाम",
      length: "लंबाई",
      width: "चौड़ाई",
      height: "ऊंचाई",
      validities: "वैधता",
      description: "विवरण",
      contactSeller: "विक्रेता से संपर्क करें",
      year: "साल",
      weight: "वजन",
      payload: "पेलोड",
      fuelType: "ईंधन प्रकार",
      transmission: "ट्रांसमिशन",
      displacement: "विस्थापन",
      power: "पावर",
      torque: "टॉर्क",
      wheels: "पहिए",
      wheelHealth: "पहिया स्वास्थ्य",
      airConditioner: "एयर कंडीशनर",
      ownersTillDate: "अब तक के मालिक",
      hypothecation: "गिरवी",
      location: "स्थान",
      Dimensions: "आयाम",
      Specifications: "विशेषताएं",
      Length: "लंबाई",
      Width: "चौड़ाई",
      Height: "ऊंचाई",
      Insurance: "बीमा",
      Permit: "परमिट",
      Tax: "कर",
      Fitness: "फिटनेस",
      Validity: "वैधता विवरण",
      diesel: "डीजल",
      petrol: "पेट्रोल",
      automatic: "स्वचालित",
      manual: "मैनुअल",
      contact: "विक्रेता से संपर्क करें",
      "Document Status": "दस्तावेज़ स्थिति",
      "RC Document": "RC दस्तावेज़",
      "Aadhaar Document": "आधार दस्तावेज़",
      Available: "उपलब्ध",
      "Not Available": "उपलब्ध नहीं",
      "Contact Seller": "विक्रेता से संपर्क करें",
      "Full Name": "पूरा नाम",
      "Phone Number": "फोन नंबर",
      "Email Address": "ईमेल पता",
      Address: "पता",
      Message: "संदेश",
      Cancel: "रद्द करें",
      "Send Inquiry": "पूछताछ भेजें",
      "Error submitting inquiry": "पूछताछ सबमिट करने में त्रुटि",
      "Network error, please try again later.":
        "नेटवर्क त्रुटि, कृपया बाद में प्रयास करें।",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
