import React from 'react';

function ProductSkeleton() {
  // Create an array of 8 to map over for generating skeletons
  const skeletons = Array.from({ length: 8 });

  return (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto">
        <div className="flex flex-wrap -m-4">
          {skeletons.map((_, index) => (
            <div className="lg:w-1/4 md:w-1/2 p-4 w-full" key={index}>
              <a className="block relative h-48 rounded overflow-hidden">
                <div className="bg-gray-300 animate-pulse h-full w-full"></div>
              </a>
              <div className="mt-4">
                <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">CATEGORY</h3>
                <h2 className="text-gray-900 title-font text-lg font-medium animate-pulse">Loading...</h2>
                <p className="mt-1 animate-pulse">~~~~~</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProductSkeleton;
