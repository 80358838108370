import React, { useState } from "react";
import { FaUser, FaEnvelope, FaLock } from "react-icons/fa";
import { FaFacebookF, FaGoogle } from "react-icons/fa";

const SignupPage = () => {
  const [tab, setTab] = useState("signup");

  return (
    <div className="bg-gradient-to-br min-h-[80vh] flex items-center justify-center">
      <div className="container mx-auto px-4">
        <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden shadow-2xl transform hover:scale-105 transition-transform duration-300">
          <div className="text-center py-6 bg-gradient-to-r from-blue-500 to-purple-600 text-white">
            <h1 className="text-3xl font-bold">Welcome</h1>
            <p className="mt-2">Join our amazing community</p>
          </div>
          <div className="p-8">
            <div className="flex justify-center mb-6">
              <button
                onClick={() => setTab("signup")}
                className={`px-4 py-2 rounded-l-md focus:outline-none transition-colors duration-300 ${
                  tab === "signup"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                Sign Up
              </button>
              <button
                onClick={() => setTab("login")}
                className={`px-4 py-2 rounded-r-md focus:outline-none transition-colors duration-300 ${
                  tab === "login"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                Login
              </button>
            </div>
            {tab === "signup" && (
              <form className="space-y-4">
                <div className="relative">
                  <FaUser className="absolute left-3 top-3 text-gray-400" />
                  <input
                    type="text"
                    className="w-full px-40 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 pl-10 pr-10" // Adjusted here
                    placeholder="Full Name"
                  />
                </div>
                <div className="relative">
                  <input
                    type="email"
                    className="w-full px-40 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 pl-10 pr-10" // Adjusted here
                    placeholder="Email"
                  />
                  <FaEnvelope className="absolute left-3 top-3 text-gray-400" />
                </div>
                <div className="relative">
                  <input
                    type="password"
                    className="w-full px-40 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 pl-10 pr-10" // Adjusted here
                    placeholder="Password"
                  />
                  <FaLock className="absolute left-3 top-3 text-gray-400" />
                </div>
                <button className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 rounded-md hover:opacity-90 transition-opacity duration-300 transform hover:scale-105">
                  Sign Up
                </button>
              </form>
            )}
            {tab === "login" && (
              <form className="space-y-4">
                <div className="relative">
                  <input
                    type="email"
                    className="w-full px-40 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 pl-10"
                    placeholder="Email"
                  />
                  <FaEnvelope className="absolute left-3 top-3 text-gray-400" />
                </div>
                <div className="relative">
                  <input
                    type="password"
                    className="w-full px-40 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 pl-10"
                    placeholder="Password"
                  />
                  <FaLock className="absolute left-3 top-3 text-gray-400" />
                </div>
                <button className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 rounded-md hover:opacity-90 transition-opacity duration-300 transform hover:scale-105">
                  Login
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
