import React from "react";
import { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "@fortawesome/fontawesome-svg-core/styles.css";
// import Navbar from "../../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { importAllImages } from "../../utils/importAllImages"; // Import the utility function
import { truckData, testimonialData } from "../../data";
import Carousel from "../../components/Carousels";
import icon1 from "../../assets/Icons/VeryLightTruck.png";
import icon2 from "../../assets/Icons/MediumTruck.png";
import icon3 from "../../assets/Icons/LightTruck.png";
import icon4 from "../../assets/Icons/HeavyTruck.png";
import category1 from "../../assets/category1.png";
import category2 from "../../assets/category2.png";
import category3 from "../../assets/category3.png";
import category4 from "../../assets/category4.png";
import category5 from "../../assets/category5.png";
import category6 from "../../assets/category6.png";
import vehicleData from "./vehicleData.json";
import ProductSkeleton from "./ProductSkeleton";
import SeachComponent from "./SearchBar/SeachComponent";
import InquiryForm from "../product/InquiryForm";
import { Trans } from "react-i18next";
import FAQ from "../../components/FAQ";

const images = importAllImages(
  require.context("../../assets/logo", false, /\.(png|jpe?g|svg)$/)
);
const logo = [icon1, icon3, icon2, icon4];

const importAllImagesProduct = (requireContext) => {
  const images = {};
  requireContext.keys().forEach((item) => {
    const imageName = item.replace("./", "").replace(/\.[^/.]+$/, ""); // Remove './' and the file extension
    images[imageName.replace(/_/g, " ")] = requireContext(item); // Map title with spaces
  });
  return images;
};
const ProductImg = importAllImagesProduct(
  require.context("../../assets/product", false, /\.(png|jpe?g|svg)$/)
);
function Home() {
  return (
    <div className="" id="home">
      <Carousel />
      <SeachComponent />
      <ProductList />
      <LogoCarousel />
      <FAQ />
      {/* <Category /> */}
      {/* <Testimonials testimonialData={testimonialData} /> */}
    </div>
  );
}

const CustomDropdown = ({ options, selectedOption, onSelect, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-64" ref={dropdownRef}>
      <button
        className="p-3 border border-gray-300 rounded-lg w-full text-left"
        onClick={toggleDropdown}
      >
        {selectedOption || placeholder}
      </button>
      {isOpen && (
        <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-lg max-h-48 overflow-y-auto shadow-lg z-10">
          {options.map((option, index) => (
            <div
              key={index}
              className="p-2 hover:bg-gray-200 cursor-pointer"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const SearchBar = () => {
  const [selectedType, setSelectedType] = useState("Buses");
  const [vehicleType, setVehicleType] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [availableModels, setAvailableModels] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (brand && selectedType) {
      setAvailableModels(vehicleData[selectedType][brand] || []);
    } else {
      setAvailableModels([]);
    }
  }, [brand, selectedType]);

  const handleSearch = () => {
    navigate(`/search?category=${vehicleType}&brand=${brand}`);
  };

  return (
    <div className="bg-gray-800 p-8 w-full max-w-7xl mx-auto mt-10 rounded-lg shadow-lg">
      <h1 className="text-3xl text-white font-bold mb-6">
        Search for Vehicles
      </h1>
      <div className="flex gap-4 mb-6">
        <button
          className={`py-2 px-6 rounded-full text-lg font-semibold transition ${
            selectedType === "Trucks"
              ? "bg-red-600 text-white"
              : "bg-white text-gray-800 border-2 border-red-600"
          }`}
          onClick={() => {
            setSelectedType("Trucks");
            setVehicleType("");
            setBrand("");
            setModel("");
          }}
        >
          Used
        </button>
        <button
          className={`py-2 px-6 rounded-full text-lg font-semibold transition ${
            selectedType === "Buses"
              ? "bg-red-600 text-white"
              : "bg-white text-gray-800 border-2 border-red-600"
          }`}
          onClick={() => {
            setSelectedType("Buses");
            setVehicleType("");
            setBrand("");
            setModel("");
          }}
        >
          New
        </button>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <fieldset className="mb-6">
          <legend className="text-lg font-semibold mb-4">
            Select Vehicle Type
          </legend>
          <div className="flex gap-8">
            <label className="flex items-center text-gray-700">
              <input
                type="radio"
                name="vehicle"
                value="Truck"
                checked={vehicleType === "Truck"}
                onChange={(e) => setVehicleType(e.target.value)}
                className="mr-2"
              />
              Truck
            </label>
            <label className="flex items-center text-gray-700">
              <input
                type="radio"
                name="vehicle"
                value="Bus"
                checked={vehicleType === "Bus"}
                onChange={(e) => setVehicleType(e.target.value)}
                className="mr-2"
              />
              Bus
            </label>
          </div>
        </fieldset>

        <div className="flex gap-4 mb-6">
          <CustomDropdown
            options={Object.keys(vehicleData[selectedType])}
            selectedOption={brand}
            onSelect={(selected) => setBrand(selected)}
            placeholder="-- Select Brand --"
          />

          <CustomDropdown
            options={availableModels}
            selectedOption={model}
            onSelect={(selected) => setModel(selected)}
            placeholder="-- Select Model --"
          />

          <button
            className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-md hover:bg-red-700 transition w-40"
            onClick={() => {
              handleSearch();
              // navigate("/search");
            }}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

// const Searching = () => {
//   const [collapseOpen, setCollapseOpen] = useState(false);
//   const [status, setStatus] = useState("Any Status");
//   const [selectedBrand, setSelectedBrand] = useState("Any Brand");
//   const [selectedModel, setSelectedModel] = useState("Any Model");

//   // Extract unique brands and models from the truck data
//   const brands = [
//     ...new Set(
//       truckData.flatMap((category) =>
//         category.trucks.map((truck) => truck.brand)
//       )
//     ),
//   ];
//   const models = [
//     ...new Set(
//       truckData.flatMap((category) =>
//         category.trucks.map((truck) => truck.model)
//       )
//     ),
//   ];

//   const handleSelect = (eventKey) => {
//     setStatus(eventKey);
//   };

//   const handleBrandSelect = (eventKey) => {
//     setSelectedBrand(eventKey);
//   };

//   const handleModelSelect = (eventKey) => {
//     setSelectedModel(eventKey);
//   };

//   const toggleCollapse = () => setCollapseOpen(!collapseOpen);

//   return (
//     <div className="container mx-auto mt-10">
//       <div className="flex justify-center">
//         <div className="w-full">
//           <div className="bg-white shadow-lg rounded-lg p-8">
//             <h5 className="text-2xl font-semibold text-center mb-6">
//               An Easier Way to Find Your All Needs
//             </h5>

//             {/* Main Search Row */}
//             <div className="flex flex-col md:flex-row gap-4 mb-4">
//               {/* Status Dropdown */}
//               <div className="w-full md:w-1/4">
//                 <DropdownButton
//                   id="dropdown-menu"
//                   title={status}
//                   onSelect={handleSelect}
//                   variant="outline-secondary"
//                   className="w-full"
//                 >
//                   <Dropdown.Item eventKey="Parts">Parts</Dropdown.Item>
//                   <Dropdown.Item eventKey="Vehicle">Vehicle</Dropdown.Item>
//                 </DropdownButton>
//               </div>

//               {/* Search Input */}
//               <div className="w-full ">
//                 <input
//                   type="text"
//                   className="form-input w-full border border-gray-300 rounded-lg py-2 px-3"
//                   placeholder="Enter Accessories or Vehicle Name"
//                 />
//               </div>

//               {/* Search Button */}
//               <div className="w-full md:w-1/4">
//                 <button className="bg-blue-600 hover:bg-blue-700 text-white w-full py-2 rounded-lg">
//                   Search Results
//                 </button>
//               </div>
//             </div>

//             {/* Advanced Search Section */}
//             <div className="mt-6">
//               <div
//                 onClick={toggleCollapse}
//                 role="button"
//                 aria-expanded={collapseOpen}
//                 className="text-blue-600 flex items-center cursor-pointer"
//               >
//                 <span>Advanced Search With Filters</span>
//                 <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
//               </div>

//               {/* Collapse for Advanced Search */}
//               <Collapse in={collapseOpen}>
//                 <div className="mt-6 p-4 bg-gray-50 rounded-lg">
//                   <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                     {/* Brand Dropdown with scrollable options */}
//                     <div>
//                       <DropdownButton
//                         id="brand-dropdown"
//                         title={selectedBrand}
//                         onSelect={handleBrandSelect}
//                         variant="outline-secondary"
//                         className="w-full"
//                       >
//                         <div className="max-h-40 overflow-y-auto">
//                           <Dropdown.Item eventKey="Any Brand">
//                             Any Brand
//                           </Dropdown.Item>
//                           {brands.map((brand, index) => (
//                             <Dropdown.Item key={index} eventKey={brand}>
//                               {brand}
//                             </Dropdown.Item>
//                           ))}
//                         </div>
//                       </DropdownButton>
//                     </div>

//                     {/* Model Dropdown with scrollable options */}
//                     <div>
//                       <DropdownButton
//                         id="model-dropdown"
//                         title={selectedModel}
//                         onSelect={handleModelSelect}
//                         variant="outline-secondary"
//                         className="w-full"
//                       >
//                         <div className="max-h-40 overflow-y-auto">
//                           <Dropdown.Item eventKey="Any Model">
//                             Any Model
//                           </Dropdown.Item>
//                           {models.map((model, index) => (
//                             <Dropdown.Item key={index} eventKey={model}>
//                               {model}
//                             </Dropdown.Item>
//                           ))}
//                         </div>
//                       </DropdownButton>
//                     </div>
//                   </div>
//                 </div>
//               </Collapse>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
const Tranding = ({ category, title, product }) => {
  if(typeof(product.image) !== "string"){
    product.image = product.thumbnail;
  }
    
  const [isInquiryOpen, setInquiryOpen] = useState(false);
  return (
    <div className="p-4 w-full ">
      <Link
        to={`/product/${product._id.$oid}`}
        className="block relative h-48 rounded overflow-hidden"
      >
        <img
          alt={title}
          className="object-cover object-center w-full h-full block"
          src={product.image} // replace spaces with underscores
        />
      </Link>
      <div className="mt-4">
        <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
          {category}
        </h3>
        <h2 className="text-gray-900 title-font text-lg font-medium">
          {title}
        </h2>
        <div className="flex justify-evenly items-center py-2">
          <div className="flex gap-2 w-full justify-between">
            <button
              onClick={() => setInquiryOpen(true)}
              className="px-4 py-2 w-full text-center text-white bg-violet-600 border border-violet-600 rounded active:text-violet-500 hover:bg-slate-950 hover:text-violet-600 focus:outline-none focus:ring"
            >
              Enquiry
            </button>
            <Link
              className="px-4 py-2 w-full text-center text-violet-600 border border-violet-600 rounded hover:bg-violet-600 hover:text-white active:bg-indigo-500 focus:outline-none focus:ring"
              to={`/product/${product._id.$oid}`}
            >
              View
            </Link>
          </div>
        </div>
      </div>
      <InquiryForm
        isOpen={isInquiryOpen}
        onClose={() => setInquiryOpen(false)}
        id={product._id.$oid}
      />
    </div>
  );
};

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Light Trucks");
  const [loading, setLoading] = useState(true);
  const categories = ["Light Trucks", "Medium Trucks", "Bus", "Tippers"];

  useEffect(() => {
    const fetchVehicles = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        const response = await axios.get(
          "/api/vehicles",
          {
            params: { category: selectedCategory },
          }
        );
        setProducts(response.data.slice(0, 8));
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchVehicles();
  }, [selectedCategory]);

  const handleClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <section className="text-gray-600 body-font 6" id="trendingvehicals">
      <h1 className="text-4xl text-gray-900 font-sans font-bold p-4 text-center">
        <Trans>Trending Vehicles</Trans>
      </h1>
      <div className="container px-5 mx-auto flex flex-wrap flex-col">
        <div className="grid md:grid-cols-4 grid-cols-2 mx-auto flex-wrap mb-4 gap-6 ">
          {categories.map((category, index) => (
            <div
              key={index}
              onClick={() => handleClick(category)}
              className={`px-3 cursor-pointer md:px-4 text-xs md:text-base py-1 sm:w-auto rounded-md justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none tracking-wider ${
                selectedCategory === category
                  ? "bg-blue-500 text-white"
                  : "bg-gray-100 text-indigo-500 border-indigo-500"
              }`}
            >
              <img src={logo[index]} alt="" className="size-10 mr-2" />
              <div className="text-center w-full">
                {category === "Light Trucks"
                  ? "Mini"
                  : category === "Medium Trucks"
                  ? "Trucks"
                  : category}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container md:px-5 flex items-center justify-center md:py-8 mx-auto w-full">
        {loading ? (
          <ProductSkeleton />
        ) : (
          <div className="grid xl:grid-cols-4 md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-1 -m-4 w-full">
            {products?.map((product, index) => (
              <div to={`/product/${index}`} key={index} className="w-full p-2">
                <Tranding
                  category={product.brand}
                  title={product.name}
                  product={product}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};
const Category = () => {
  const blogPosts = [
    {
      category: "Truck Parts",
      title: "Essential Truck Parts Every Owner Should Know",
      description:
        "Discover the vital truck parts that ensure optimal performance and longevity of your vehicle. Learn about their functions, maintenance tips, and common issues.",
      image: category1,
      views: "2500",
      comments: 30,
      parts: [
        {
          name: "Heavy-Duty Brake Pads",
          part_number: "HDBP1001",
          compatible_vehicles: [
            "Tata LPT 2518",
            "Ashok Leyland 1616",
            "Mahindra Furio 7",
          ],
          material: "Metallic",
          cost: 3000,
          warranty: "1 year",
          description:
            "Robust brake pads designed to handle heavy loads and provide superior stopping power.",
          features: [
            "High friction material",
            "Excellent heat resistance",
            "Long-lasting durability",
          ],
          indicators_for_replacement: [
            "Squeaking noise during braking",
            "Vibration in brake pedal",
            "Decreased stopping performance",
          ],
        },
        {
          name: "Fuel Filter",
          part_number: "FF2002",
          compatible_vehicles: [
            "Isuzu NPR 400",
            "Eicher Pro 6000",
            "Bharat Benz 3123",
          ],
          material: "Synthetic",
          cost: 1200,
          warranty: "1 year",
          description:
            "A high-efficiency fuel filter that keeps contaminants out of the fuel system, ensuring optimal engine performance.",
          features: [
            "Increased fuel flow",
            "Improved fuel efficiency",
            "Easy installation",
          ],
          indicators_for_replacement: [
            "Reduced engine performance",
            "Engine misfires",
            "Unusual engine noises",
          ],
        },
        {
          name: "Transmission Oil Cooler",
          part_number: "TOC3003",
          compatible_vehicles: ["Tata 1212 LPK", "Mahindra M7", "Scania R 500"],
          material: "Aluminum",
          cost: 5000,
          warranty: "2 years",
          description:
            "An essential component for maintaining optimal transmission temperatures under heavy load.",
          features: [
            "Efficient heat exchange",
            "Durable construction",
            "Reduces transmission wear",
          ],
          indicators_for_replacement: [
            "Overheating transmission",
            "Fluid leaks",
            "Unresponsive shifting",
          ],
        },
      ],
    },
    {
      category: "Light Trucks",
      title: "Top Light Trucks in India for 2023",
      description:
        "Explore the best light trucks available in India this year, their specifications, and what makes them stand out.",
      image: category2,
      views: "1500",
      comments: 30,
      trucks: [
        {
          name: "Tata Ace",
          manufacture_year: 2022,
          brand: "Tata",
          model: "Ace",
          area: "India",
          height: "66.5 inches",
          cost: 600000,
          wheel_count: 4,
          payload_capacity: "1000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Mahindra Bolero Pik-Up",
          manufacture_year: 2022,
          brand: "Mahindra",
          model: "Bolero Pik-Up",
          area: "India",
          height: "75 inches",
          cost: 800000,
          wheel_count: 4,
          payload_capacity: "1200 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Ashok Leyland Dost",
          manufacture_year: 2022,
          brand: "Ashok Leyland",
          model: "Dost",
          area: "India",
          height: "69.1 inches",
          cost: 700000,
          wheel_count: 4,
          payload_capacity: "1200 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Isuzu D-Max V-Cross",
          manufacture_year: 2023,
          brand: "Isuzu",
          model: "D-Max V-Cross",
          area: "India",
          height: "71.5 inches",
          cost: 1900000,
          wheel_count: 4,
          payload_capacity: "1000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Mahindra Scorpio Pik-Up",
          manufacture_year: 2022,
          brand: "Mahindra",
          model: "Scorpio Pik-Up",
          area: "India",
          height: "72 inches",
          cost: 1100000,
          wheel_count: 4,
          payload_capacity: "1200 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Tata Intra V10",
          manufacture_year: 2022,
          brand: "Tata",
          model: "Intra V10",
          area: "India",
          height: "69.6 inches",
          cost: 600000,
          wheel_count: 4,
          payload_capacity: "1000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Eicher Pro 2049",
          manufacture_year: 2022,
          brand: "Eicher",
          model: "Pro 2049",
          area: "India",
          height: "78.1 inches",
          cost: 850000,
          wheel_count: 4,
          payload_capacity: "2000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Force Trax",
          manufacture_year: 2023,
          brand: "Force",
          model: "Trax",
          area: "India",
          height: "76 inches",
          cost: 700000,
          wheel_count: 4,
          payload_capacity: "1000 kg",
          fuel_type: "Diesel",
        },
      ],
    },
    {
      category: "Medium Trucks",
      title: "Best Medium Trucks in India for 2023",
      description:
        "Discover the most reliable medium trucks that are perfect for various business needs.",
      image: category3,
      views: "1200",
      comments: 20,
      trucks: [
        {
          name: "Ashok Leyland 1616",
          manufacture_year: 2021,
          brand: "Ashok Leyland",
          model: "1616",
          area: "India",
          height: "90 inches",
          cost: 1800000,
          wheel_count: 6,
          payload_capacity: "16000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Tata LPT 1613",
          manufacture_year: 2022,
          brand: "Tata",
          model: "LPT 1613",
          area: "India",
          height: "100 inches",
          cost: 1600000,
          wheel_count: 6,
          payload_capacity: "15000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Mahindra Furio 7",
          manufacture_year: 2022,
          brand: "Mahindra",
          model: "Furio 7",
          area: "India",
          height: "105 inches",
          cost: 2200000,
          wheel_count: 6,
          payload_capacity: "17000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Eicher Pro 6000",
          manufacture_year: 2023,
          brand: "Eicher",
          model: "Pro 6000",
          area: "India",
          height: "95 inches",
          cost: 2100000,
          wheel_count: 6,
          payload_capacity: "20000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Tata LPK 2518",
          manufacture_year: 2022,
          brand: "Tata",
          model: "LPK 2518",
          area: "India",
          height: "105 inches",
          cost: 2400000,
          wheel_count: 6,
          payload_capacity: "18000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Bharat Benz 1617",
          manufacture_year: 2023,
          brand: "Bharat Benz",
          model: "1617",
          area: "India",
          height: "90 inches",
          cost: 2000000,
          wheel_count: 6,
          payload_capacity: "16000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Force Traveller",
          manufacture_year: 2022,
          brand: "Force",
          model: "Traveller",
          area: "India",
          height: "85 inches",
          cost: 1300000,
          wheel_count: 6,
          payload_capacity: "1500 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Isuzu NPR 400",
          manufacture_year: 2023,
          brand: "Isuzu",
          model: "NPR 400",
          area: "India",
          height: "95 inches",
          cost: 2400000,
          wheel_count: 6,
          payload_capacity: "20000 kg",
          fuel_type: "Diesel",
        },
      ],
    },
    {
      category: "Heavy Trucks",
      title: "Leading Heavy Trucks in India for 2023",
      description:
        "Find out which heavy trucks are making waves in the Indian market this year.",
      image: category4,
      views: "1800",
      comments: 25,
      trucks: [
        {
          name: "Tata 1212 LPK",
          manufacture_year: 2021,
          brand: "Tata",
          model: "1212 LPK",
          area: "India",
          height: "110 inches",
          cost: 2800000,
          wheel_count: 10,
          payload_capacity: "12000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Ashok Leyland 2518",
          manufacture_year: 2022,
          brand: "Ashok Leyland",
          model: "2518",
          area: "India",
          height: "105 inches",
          cost: 3200000,
          wheel_count: 10,
          payload_capacity: "20000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Bharat Benz 3123",
          manufacture_year: 2023,
          brand: "Bharat Benz",
          model: "3123",
          area: "India",
          height: "110 inches",
          cost: 3500000,
          wheel_count: 10,
          payload_capacity: "23000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Volvo FM 440",
          manufacture_year: 2022,
          brand: "Volvo",
          model: "FM 440",
          area: "India",
          height: "115 inches",
          cost: 6000000,
          wheel_count: 10,
          payload_capacity: "30000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Scania P 250",
          manufacture_year: 2023,
          brand: "Scania",
          model: "P 250",
          area: "India",
          height: "115 inches",
          cost: 6500000,
          wheel_count: 10,
          payload_capacity: "25000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Man Trucks TGX 18.640",
          manufacture_year: 2022,
          brand: "Man",
          model: "TGX 18.640",
          area: "India",
          height: "115 inches",
          cost: 7000000,
          wheel_count: 10,
          payload_capacity: "20000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Isuzu Giga",
          manufacture_year: 2023,
          brand: "Isuzu",
          model: "Giga",
          area: "India",
          height: "120 inches",
          cost: 7500000,
          wheel_count: 10,
          payload_capacity: "24000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Eicher Pro 6025",
          manufacture_year: 2022,
          brand: "Eicher",
          model: "Pro 6025",
          area: "India",
          height: "110 inches",
          cost: 3000000,
          wheel_count: 10,
          payload_capacity: "22000 kg",
          fuel_type: "Diesel",
        },
      ],
    },
    {
      category: "Dump Trucks",
      title: "Top Dump Trucks in India for 2023",
      description:
        "Check out the most efficient dump trucks for construction and mining in India this year.",
      image: category5,
      views: "1600",
      comments: 15,
      trucks: [
        {
          name: "Tata 2518 HD",
          manufacture_year: 2022,
          brand: "Tata",
          model: "2518 HD",
          area: "India",
          height: "115 inches",
          cost: 3600000,
          wheel_count: 10,
          payload_capacity: "18000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Ashok Leyland 3118",
          manufacture_year: 2022,
          brand: "Ashok Leyland",
          model: "3118",
          area: "India",
          height: "110 inches",
          cost: 4000000,
          wheel_count: 10,
          payload_capacity: "20000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Bharat Benz 2523",
          manufacture_year: 2023,
          brand: "Bharat Benz",
          model: "2523",
          area: "India",
          height: "110 inches",
          cost: 4200000,
          wheel_count: 10,
          payload_capacity: "23000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Eicher Pro 6016",
          manufacture_year: 2022,
          brand: "Eicher",
          model: "Pro 6016",
          area: "India",
          height: "115 inches",
          cost: 3800000,
          wheel_count: 10,
          payload_capacity: "20000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Volvo FMX 410",
          manufacture_year: 2023,
          brand: "Volvo",
          model: "FMX 410",
          area: "India",
          height: "120 inches",
          cost: 7000000,
          wheel_count: 10,
          payload_capacity: "25000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Scania G 410",
          manufacture_year: 2023,
          brand: "Scania",
          model: "G 410",
          area: "India",
          height: "120 inches",
          cost: 7500000,
          wheel_count: 10,
          payload_capacity: "25000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Man TGS 18.440",
          manufacture_year: 2022,
          brand: "Man",
          model: "TGS 18.440",
          area: "India",
          height: "115 inches",
          cost: 6800000,
          wheel_count: 10,
          payload_capacity: "20000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Hyundai HD65",
          manufacture_year: 2023,
          brand: "Hyundai",
          model: "HD65",
          area: "India",
          height: "110 inches",
          cost: 3000000,
          wheel_count: 10,
          payload_capacity: "18000 kg",
          fuel_type: "Diesel",
        },
      ],
    },
    {
      category: "Refrigerated Trucks",
      title: "Best Refrigerated Trucks in India for 2023",
      description:
        "Learn about the top refrigerated trucks suitable for transporting perishable goods in India.",
      image: category6,
      views: "1400",
      comments: 18,
      trucks: [
        {
          name: "Mahindra Bolero Refrigerated",
          manufacture_year: 2022,
          brand: "Mahindra",
          model: "Bolero Refrigerated",
          area: "India",
          height: "75 inches",
          cost: 1300000,
          wheel_count: 4,
          payload_capacity: "1000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Tata Ultra Refrigerated",
          manufacture_year: 2022,
          brand: "Tata",
          model: "Ultra Refrigerated",
          area: "India",
          height: "84 inches",
          cost: 1500000,
          wheel_count: 4,
          payload_capacity: "1200 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Isuzu Reefer Truck",
          manufacture_year: 2023,
          brand: "Isuzu",
          model: "Reefer Truck",
          area: "India",
          height: "71 inches",
          cost: 2000000,
          wheel_count: 4,
          payload_capacity: "1500 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Eicher Pro 3015",
          manufacture_year: 2022,
          brand: "Eicher",
          model: "Pro 3015",
          area: "India",
          height: "80 inches",
          cost: 1700000,
          wheel_count: 4,
          payload_capacity: "2000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Force Trax Refrigerated",
          manufacture_year: 2023,
          brand: "Force",
          model: "Trax Refrigerated",
          area: "India",
          height: "76 inches",
          cost: 1200000,
          wheel_count: 4,
          payload_capacity: "1000 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Mahindra Scorpio Refrigerated",
          manufacture_year: 2022,
          brand: "Mahindra",
          model: "Scorpio Refrigerated",
          area: "India",
          height: "72 inches",
          cost: 1500000,
          wheel_count: 4,
          payload_capacity: "1200 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Tata Intra Refrigerated",
          manufacture_year: 2023,
          brand: "Tata",
          model: "Intra Refrigerated",
          area: "India",
          height: "69.6 inches",
          cost: 900000,
          wheel_count: 4,
          payload_capacity: "800 kg",
          fuel_type: "Diesel",
        },
        {
          name: "Ashok Leyland Refrigerated",
          manufacture_year: 2023,
          brand: "Ashok Leyland",
          model: "Refrigerated",
          area: "India",
          height: "70 inches",
          cost: 1300000,
          wheel_count: 4,
          payload_capacity: "1000 kg",
          fuel_type: "Diesel",
        },
      ],
    },
  ];

  return (
    <section className="text-gray-600 body-font">
      <h1 className=" text-4xl text-center text-gray-900 font-sans font-bold px-4">
        Category
      </h1>
      <div className="container md:px-5 py-14 mx-auto">
        <div className="grid md:grid-cols-3 flex-wrap md:px-10 grid-cols-1">
          {blogPosts.map((post, index) => (
            <div key={index} className="p-4">
              <Link>
                <div className="h-full  border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                  <img
                    className="lg:h-48 md:h-36 w-full object-cover object-center"
                    src={post.image}
                    alt="blog"
                  />
                  <div className="p-6">
                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                      {post.category}
                    </h2>
                    <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                      {post.title}
                    </h1>
                    <p className="leading-relaxed mb-3">{post.description}</p>
                    <div className="flex items-center flex-wrap">
                      <div className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
                        Learn More
                        <svg
                          className="w-4 h-4 ml-2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M5 12h14"></path>
                          <path d="M12 5l7 7-7 7"></path>
                        </svg>
                      </div>
                      <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                        <svg
                          className="w-4 h-4 mr-1"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          viewBox="0 0 24 24"
                        >
                          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                          <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                        {post.views}
                      </span>
                      <span className="text-gray-400 inline-flex items-center leading-none text-sm">
                        <svg
                          className="w-4 h-4 mr-1"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          viewBox="0 0 24 24"
                        >
                          <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                        </svg>
                        {post.comments}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const LogoCarousel = () => {
  const [activeIdx, setActiveIdx] = useState(0);
  const logoCount = Object.keys(images).length;
  const visibleLogos = 5; // Number of logos visible at once

  // Autoplay feature: change logo every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIdx((prev) => (prev + 1) % (logoCount - visibleLogos + 1)); // Ensure we only loop through visible logos
    }, 3000);
    return () => clearInterval(interval);
  }, [logoCount]);

  const nextClick = () => {
    setActiveIdx((prev) => (prev + 1) % (logoCount - visibleLogos + 1));
  };

  const prevClick = () => {
    setActiveIdx(
      (prev) =>
        (prev - 1 + (logoCount - visibleLogos + 1)) %
        (logoCount - visibleLogos + 1)
    );
  };

  return (
    <div className="bg-white py-12 px-4 md:px-8" id="brands">
      <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-8">
        Our Trusted Brands
      </h1>
      <div className="relative flex items-center justify-center w-full overflow-hidden">
        <div className="relative w-full max-w-5xl mx-auto">
          {/* Carousel Content */}
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(-${(activeIdx / logoCount) * 100}%)`,
              width: `${(logoCount / visibleLogos) * 100}%`,
            }}
          >
            {Object.keys(images).map((key, index) => (
              <div
                key={index}
                className="flex-none px-6"
                style={{ width: `${100 / logoCount}%` }}
              >
                {/* Dynamic URL based on image name */}
                <Link
                  to={`/search?brand=${encodeURIComponent(key).replace(
                    ".png",
                    ""
                  )}`}
                >
                  <img
                    src={images[key]}
                    alt={`Logo ${index + 1}`}
                    className="w-full h-32 object-contain mx-auto"
                  />
                </Link>
              </div>
            ))}
          </div>

          {/* Previous Button */}
          <button
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white shadow-lg rounded-full p-3 text-gray-700 hover:bg-gray-100 transition-all ease-in-out"
            onClick={prevClick}
          >
            &lt;
          </button>

          {/* Next Button */}
          <button
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white shadow-lg rounded-full p-3 text-gray-700 hover:bg-gray-100 transition-all ease-in-out"
            onClick={nextClick}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

const Testimonials = ({ testimonialData }) => {
  return (
    <section
      id="testimonialData"
      aria-label="What our customers are saying"
      className="bg-slate-50 py-20 sm:py-32"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl font-black tracking-tight text-slate-900 sm:text-4xl relative inline-block">
            What Our Customers Are Saying
            <span
              className="absolute -my-0.5 left-0 bottom-0 w-full h-1 bg-gradient-to-r from-indigo-500 via-blue-500 to-indigo-500 rounded"
              style={{ bottom: "-0.5rem" }}
            ></span>
          </h2>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          {testimonialData.map((testimonial, index) => (
            <li key={index}>
              <ul role="list" className="flex flex-col gap-y-6 sm:gap-y-8">
                <li>
                  <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                    <svg
                      aria-hidden="true"
                      width="105"
                      height="78"
                      className="absolute left-6 top-6 fill-slate-100"
                    >
                      <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"></path>
                    </svg>
                    <blockquote className="relative">
                      <p className="text-lg tracking-tight text-slate-900">
                        {testimonial.text}
                      </p>
                    </blockquote>
                    <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                      <div>
                        <div className="font-display text-base text-slate-900">
                          {testimonial.author}
                        </div>
                        <div className="text-sm text-slate-500">
                          {testimonial.position}
                        </div>
                      </div>
                      <div className="overflow-hidden rounded-full bg-slate-50">
                        <img
                          alt={testimonial.author}
                          className="h-14 w-14 object-cover"
                          style={{ color: "transparent" }}
                          src={testimonial.avatarUrl}
                        />
                      </div>
                    </figcaption>
                  </figure>
                </li>
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

// const InquiryForm = ({ isOpen, onClose, id }) => {
//   const [name, setName] = useState("");
//   const [phone, setPhone] = useState("");
//   const [email, setEmail] = useState(""); // Email state
//   const [address, setAddress] = useState(""); // New address state
//   const [description, setDescription] = useState("");
//   const [error, setError] = useState("");

//   useEffect(() => {
//     if (isOpen) {
//       document.body.style.overflow = "hidden"; // Disable scrolling
//     } else {
//       document.body.style.overflow = "auto"; // Enable scrolling
//     }

//     return () => {
//       document.body.style.overflow = "auto"; // Reset on unmount
//     };
//   }, [isOpen]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const inquiryData = {
//       name,
//       phone,
//       email,
//       address,
//       description,
//       vehicle_id: id,
//     }; // Include address in inquiry data

//     try {
//       const response = await fetch("/api/inquiries", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(inquiryData),
//       });

//       if (response.ok) {
//         alert("Inquiry submitted successfully!"); // Show success alert
//         setName(""); // Clear form fields
//         setPhone("");
//         setEmail("");
//         setAddress("");
//         setDescription("");
//         onClose(); // Close the form after submission
//       } else {
//         const errorData = await response.json();
//         setError(errorData.message || "Error submitting inquiry");
//       }
//     } catch (error) {
//       console.error("Network error:", error);
//       setError("Network error, please try again later.");
//     }
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//       <div className="bg-white rounded-lg shadow-lg p-6 w-96">
//         <h2 className="text-xl font-bold mb-4">Inquiry Form</h2>
//         {error && <div className="text-red-600 mb-4">{error}</div>}
//         <form onSubmit={handleSubmit}>
//           <div className="mb-4">
//             <label className="block text-gray-700 mb-2" htmlFor="name">
//               Name
//             </label>
//             <input
//               type="text"
//               id="name"
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//               className="w-full border rounded-lg p-2 focus:outline-none focus:ring focus:ring-violet-500"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-700 mb-2" htmlFor="phone">
//               Phone Number
//             </label>
//             <input
//               type="tel"
//               id="phone"
//               value={phone}
//               onChange={(e) => setPhone(e.target.value)}
//               className="w-full border rounded-lg p-2 focus:outline-none focus:ring focus:ring-violet-500"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-700 mb-2" htmlFor="email">
//               Email Address
//             </label>
//             <input
//               type="email"
//               id="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               className="w-full border rounded-lg p-2 focus:outline-none focus:ring focus:ring-violet-500"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-700 mb-2" htmlFor="address">
//               Address
//             </label>
//             <input
//               type="text"
//               id="address"
//               value={address}
//               onChange={(e) => setAddress(e.target.value)}
//               className="w-full border rounded-lg p-2 focus:outline-none focus:ring focus:ring-violet-500"
//               required
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-700 mb-2" htmlFor="description">
//               Description
//             </label>
//             <textarea
//               id="description"
//               value={description}
//               onChange={(e) => setDescription(e.target.value)}
//               className="w-full border rounded-lg p-2 focus:outline-none focus:ring focus:ring-violet-500"
//               rows="4"
//               required
//             ></textarea>
//           </div>
//           <div className="flex justify-between">
//             <button
//               type="button"
//               onClick={onClose}
//               className="text-gray-500 hover:text-gray-700"
//             >
//               Cancel
//             </button>
//             <button
//               type="submit"
//               className="bg-violet-600 text-white px-4 py-2 rounded-lg hover:bg-violet-500"
//             >
//               Submit
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

export default Home;

// Make sure to add the Tailwind CSS to your project to style this component correctly.
