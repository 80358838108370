import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ChevronDown,
  FileText,
  Trash2,
  RefreshCw,
  AlertCircle,
  CheckCircle2,
  Clock,
  XCircle,
  User,
  Phone,
  Mail,
  MapPin,
  Car,
  DollarSign,
  FileCheck,
  LogOut,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

// Status badge component
const StatusBadge = ({ status }) => {
  const statusConfig = {
    Pending: {
      icon: Clock,
      className: "bg-yellow-100 text-yellow-800 border-yellow-200",
    },
    Discard: {
      icon: XCircle,
      className: "bg-red-100 text-red-800 border-red-200",
    },
    Evaluating: {
      icon: RefreshCw,
      className: "bg-blue-100 text-blue-800 border-blue-200",
    },
    Confirm: {
      icon: CheckCircle2,
      className: "bg-green-100 text-green-800 border-green-200",
    },
  };

  const { icon: Icon, className } =
    statusConfig[status] || statusConfig.Pending;

  return (
    <span
      className={`inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full text-sm font-medium border ${className}`}
    >
      <Icon size={16} />
      {status}
    </span>
  );
};

// Info Row component
const InfoRow = ({ icon: Icon, label, value }) => (
  <div className="flex items-center justify-between py-2 border-b border-gray-100 last:border-0">
    <div className="flex items-center gap-2 text-gray-600">
      <Icon size={16} />
      <span className="text-sm font-medium">{label}</span>
    </div>
    <span className="text-sm font-semibold text-gray-900">{value}</span>
  </div>
);

const AdminPage = ({ setLoggedIn }) => {
  const [inquiries, setInquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  // console.log(inquiries)
  useEffect(() => {
    fetchInquiries();
  }, []);

  const fetchInquiries = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "/api/inquiries"
      );
      setInquiries(response.data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch inquiries. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (inquiryId, status) => {
    console.log(inquiryId);
    try {
      await axios.patch(
        `/api/inquiries/${inquiryId}/status`,
        { status }
      );
      setInquiries((prev) =>
        prev.map((inquiry) =>
          inquiry.inquiry._id === inquiryId
            ? { ...inquiry, inquiry: { ...inquiry.inquiry, status } }
            : inquiry
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
    setActiveDropdown(null);
  };

  const handleDelete = async (inquiryId) => {
    if (!window.confirm("Are you sure you want to delete this product?"))
      return;

    try {
      await axios
        .delete(`/api/inquiries/${inquiryId}`)
        .then((res) => console.log(res));
      setInquiries((prev) =>
        prev.filter((inquiry) => inquiry.inquiry._id !== inquiryId)
      );
      fetchInquiries();
    } catch (error) {
      console.error("Error deleting inquiry:", error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="flex items-center gap-3">
          <RefreshCw className="w-6 h-6 animate-spin text-blue-600" />
          <span className="text-lg font-medium text-gray-700">
            Loading inquiries...
          </span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center space-y-4">
          <AlertCircle className="w-12 h-12 text-red-500 mx-auto" />
          <div className="text-lg font-medium text-gray-900">{error}</div>
          <button
            onClick={fetchInquiries}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold text-gray-900">
            Vehicle Inquiries
          </h1>
          <div className="flex gap-2">
          <button
            onClick={()=>setLoggedIn(false)}
            className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition"
          >
            <LogOut size={16}/>
            Logout
          </button>
            <button
              onClick={fetchInquiries}
              className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition"
            >
              <RefreshCw size={16} />
              Refresh
            </button>
          </div>
        </div>

        <div className="space-y-6">
          {inquiries.map((inquiry) => (
            <div
              key={inquiry.inquiry._id}
              className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden"
            >
              <div className="p-6">
                <div
                  // onClick={}
                  onClick={() => navigate("/product/" + inquiry.vehicle._id)}
                  className="flex items-center cursor-pointer justify-between mb-6"
                >
                  <div className="flex items-center gap-4">
                    <img
                      src={inquiry.vehicle.image}
                      alt="Vehicle"
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                    <div>
                      <h2 className="text-xl font-semibold text-gray-900">
                        {inquiry.vehicle.name}
                      </h2>
                      {inquiry?.vehicle?.location?.[1] &&
                        inquiry?.vehicle?.location?.[0] && (
                          <div className="py-2 text-gray-600">
                            <MapPin className="inline-block w-5 h-5 mr-1" />
                            <span>
                              {inquiry?.vehicle?.location?.[1]},{" "}
                              {inquiry?.vehicle?.location?.[0]}
                            </span>
                          </div>
                        )}
                      <p className="text-sm text-gray-500">
                        Inquiry received on{" "}
                        {new Date(
                          inquiry.inquiry.created_at
                        ).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                  <StatusBadge status={inquiry.inquiry.status} />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="space-y-4">
                    <h3 className="text-lg font-semibold text-gray-900">
                      Vehicle Details
                    </h3>
                    <div className="space-y-1">
                      <InfoRow
                        icon={User}
                        label="Owner"
                        value={inquiry.vehicle.ower_info?.name}
                      />
                      <InfoRow
                        icon={Phone}
                        label="Phone"
                        value={inquiry.vehicle.ower_info?.phone}
                      />
                      <InfoRow
                        icon={Mail}
                        label="Email"
                        value={inquiry.vehicle.ower_info?.email}
                      />
                      <InfoRow
                        icon={MapPin}
                        label="Address"
                        value={inquiry.vehicle.ower_info?.address}
                      />
                      <InfoRow
                        icon={DollarSign}
                        label="Cost"
                        value={`Rs. ${inquiry?.vehicle?.cost}`}
                      />
                    </div>
                  </div>

                  <div className="space-y-4">
                    <h3 className="text-lg font-semibold text-gray-900">
                      Inquiry Details
                    </h3>
                    <div className="space-y-1">
                      <InfoRow
                        icon={User}
                        label="Name"
                        value={inquiry.inquiry.name}
                      />
                      <InfoRow
                        icon={Phone}
                        label="Phone"
                        value={inquiry.inquiry.phone}
                      />
                      <InfoRow
                        icon={Mail}
                        label="Email"
                        value={inquiry.inquiry.email}
                      />
                      <InfoRow
                        icon={MapPin}
                        label="Address"
                        value={inquiry.inquiry.address}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6 pt-6 border-t border-gray-100">
                  <div className="flex items-center gap-4">
                    {inquiry.vehicle.documents?.rc === "yes" && (
                      <div className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-700 rounded-lg hover:bg-blue-100 transition">
                        <FileCheck size={16} />
                        RC Available
                      </div>
                    )}
                    {inquiry.vehicle.documents?.aadhaar === "yes" && (
                      <div className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-700 rounded-lg hover:bg-blue-100 transition">
                        <FileText size={16} />
                        Aadhaar Available
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-6 flex items-center justify-between">
                  {/* Dropdown for updating status */}
                  <div className="absolute">
                    <button
                      onClick={() =>
                        setActiveDropdown(
                          activeDropdown === inquiry._id ? null : inquiry._id
                        )
                      }
                      className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition"
                    >
                      Update Status
                      <ChevronDown
                        size={16}
                        className={`transform transition-transform ${
                          activeDropdown === inquiry._id ? "rotate-180" : ""
                        }`}
                      />
                    </button>

                    {activeDropdown === inquiry._id && (
                      <div className="absolute z-20 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200">
                        {[
                          "Pending",
                          "Discard",
                          "Evaluating",
                          "Confirm",
                          "Hide",
                        ].map((status) => (
                          <button
                            key={status}
                            onClick={() => {
                              handleStatusChange(inquiry.inquiry._id, status); // Call status change handler
                              setActiveDropdown(null); // Close dropdown after selection
                            }}
                            className="w-full text-left px-4 py-2 hover:bg-gray-50 first:rounded-t-lg last:rounded-b-lg"
                          >
                            <StatusBadge status={status} />
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                  yyyyyyyyyyyyyyyyy
                  {/* Delete button */}
                  <button
                    onClick={() => handleDelete(inquiry.inquiry._id)} // Call delete handler
                    className="flex items-center gap-2 px-4 py-2 bg-red-50 text-red-700 rounded-lg hover:bg-red-100 transition"
                  >
                    <Trash2 size={16} />
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}

          {inquiries.length === 0 && (
            <div className="text-center py-12">
              <Car className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900">
                No inquiries found
              </h3>
              <p className="text-gray-500">
                New inquiries will appear here when customers submit them.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
