import { motion } from 'framer-motion';
import { Check } from 'lucide-react';

interface FilterPanelProps {
  products: any[];
  selectedBrands: string[];
  selectedTypes: string[];
  onBrandChange: (brand: string) => void;
  onTypeChange: (type: string) => void;
}

export function FilterPanel({
  products,
  selectedBrands,
  selectedTypes,
  onBrandChange,
  onTypeChange,
}: FilterPanelProps) {
  const uniqueBrands = [...new Set(products.map(product => product.brand || 'Unknown'))];
  const uniqueTypes = [...new Set(products.map(product => product.category))];

  return (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      {/* Brands Section */}
      <div>
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Brands</h3>
        <div className="space-y-2">
          {uniqueBrands.map(brand => (
            <motion.button
              key={brand}
              onClick={() => onBrandChange(brand)}
              className={`w-full flex items-center justify-between px-3 py-2 rounded-lg text-sm ${
                selectedBrands.includes(brand)
                  ? 'bg-violet-50 text-violet-900'
                  : 'text-gray-700 hover:bg-gray-50'
              }`}
              whileTap={{ scale: 0.98 }}
            >
              <span>{brand}</span>
              {selectedBrands.includes(brand) && (
                <Check className="w-4 h-4 text-violet-600" />
              )}
            </motion.button>
          ))}
        </div>
      </div>

      {/* Types Section */}
      <div>
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Vehicle Types</h3>
        <div className="space-y-2">
          {uniqueTypes.map(type => (
            <motion.button
              key={type}
              onClick={() => onTypeChange(type)}
              className={`w-full flex items-center justify-between px-3 py-2 rounded-lg text-sm ${
                selectedTypes.includes(type)
                  ? 'bg-violet-50 text-violet-900'
                  : 'text-gray-700 hover:bg-gray-50'
              }`}
              whileTap={{ scale: 0.98 }}
            >
              <span>{type}</span>
              {selectedTypes.includes(type) && (
                <Check className="w-4 h-4 text-violet-600" />
              )}
            </motion.button>
          ))}
        </div>
      </div>
    </div>
  );
}