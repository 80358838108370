import React from "react";
import { Calendar } from "lucide-react";
import { useTranslation } from "react-i18next";

interface ValidityProps {
  validities: {
    insurance_validity?: string,
    permit_validity?: string,
    tax_validity?: string,
    fitness_validity?: string,
  };
}

export default function ValiditySection({ validities }: ValidityProps) {
  const { t } = useTranslation();
  const translateField = (field) => {
    if (!field) return '';
    // Check if translation exists, else return the original field
    return t(field, { defaultValue: field });
  };

  if (!validities) return null;

  const validityItems = [
    { label: "Insurance", value: validities.insurance_validity },
    { label: "Permit", value: validities.permit_validity },
    { label: "Tax", value: validities.tax_validity },
    { label: "Fitness", value: validities.fitness_validity },
  ].filter((item) => item.value);

  if (!validityItems.length) return null;

  return (
    <div className="mt-8">
      <h2 className="text-lg font-semibold mb-4">{t("Validity")}</h2>
      <div className="grid grid-cols-2 gap-4">
        {validityItems.map((item, index) => (
          <div key={index} className="flex items-center space-x-3">
            <div className="p-2 bg-green-50 rounded-lg text-green-600">
              <Calendar className="w-5 h-5" />
            </div>
            <div>
              <div className="text-sm text-gray-500">{translateField(item.label)}</div>
              <div className="font-medium text-gray-900">
                {new Date(item.value).toLocaleDateString()}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
