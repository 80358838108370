import React from "react";
import Home from "../features/home/Home";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AIbot from "../components/AIbot";


function HomePage() {
  return (
    <div>
      <Navbar />
      <Home />
      <Footer />
      <AIbot />
    </div>
  );
}

export default HomePage;
