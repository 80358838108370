import React, { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { RxAvatar } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import WebsiteLogo from "../assets/WebsiteLogo.jpg";

const Navbar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [SearchBar, setSearchBar] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null); // Reference for the dropdown menu
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsActive(false); // Close the menu if clicked outside
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);
  const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);

  return (
    <nav className="bg-white  border-gray-200 shadow-md">
      <div className="container mx-auto flex flex-wrap items-center justify-between p-2">
        <Link to="/" className="flex items-center gap-2">
          <img
            src={WebsiteLogo}
            alt="logo"
            className="w-24 h-24 rounded-full"
          />
          <span className="text-xl font-serif font-bold">GAADI MARKET</span>
        </Link>

        <button
          onClick={toggleMobileMenu}
          type="button"
          className="md:hidden ml-3 text-gray-400 hover:text-gray-900 focus:outline-none"
          aria-controls="mobile-menu"
          aria-expanded={isMobileMenuOpen}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>

        <div
          className={`${
            isMobileMenuOpen ? "block" : "hidden"
          } md:block w-full md:w-auto`}
          id="mobile-menu"
        >
          <ul className="flex-col md:flex-row flex md:space-x-8 mt-4 md:mt-0 md:text-sm md:font-medium">
            <li>
              <Link
                to={"/"}
                className="text-blue-700 md:text-gray-700 block p-2"
                aria-current="page"
              >
                Home
              </Link>
            </li>
            <li>
              <a
                href="#searchvehicals"
                className="text-gray-700 hover:bg-gray-50 block p-2 md:p-0"
              >
                Search Vehicals
              </a>
            </li>
            <li>
              <a
                href="#trendingvehicals"
                className="text-gray-700 hover:bg-gray-50 block p-2 md:p-0"
              >
                Trending Vehicals
              </a>
            </li>
            <li>
              <a
                href="#brands"
                className="text-gray-700 hover:bg-gray-50 block p-2 md:p-0"
              >
                Trusted Brands
              </a>
            </li>
            <li>
              <Link
                to={"/addproduct"}
                className="text-gray-700 hover:bg-gray-50 block p-2 md:p-0"
              >
                Sell Vehicals
              </Link>
            </li>
            {/* <li>
              <Link
                to={"/admin"}
                className="text-gray-700 hover:bg-gray-50 block p-2 md:p-0"
              >
                Admin
              </Link>
            </li> */}
            {/* <li onClick={() => setSearchBar(!SearchBar)}>
              <div
                className="text-blue-700 md:text-gray-700 block p-2 cursor-pointer"
                aria-current="page"
              >
                <FaSearch size={24} />
              </div>
            </li> */}
            {/* <li className="flex items-center justify-center">
              <div className="relative w-9 mx-auto">
                <div onClick={toggleMenu} className="cursor-pointer">
                  <RxAvatar size={32} />
                </div>

                {isActive && (
                  <div
                    ref={menuRef} // Attach the ref to the dropdown menu
                    className="absolute right-0 z-10 bg-white w-64 rounded border border-gray-300 mt-2 shadow-lg"
                  >
                    <div className="flex border-b border-gray-300 p-3">
                      <img
                        src="https://images.unsplash.com/photo-1578976563986-fb8769ab695e?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80"
                        alt="Profile"
                        className="w-12 h-12 rounded-full object-cover"
                      />
                      <div className="ml-3">
                        <div className="font-semibold text-lg">User</div>
                        <div className="font-light text-sm">+91 8461991146</div>
                      </div>
                    </div>

                    <ul className="list-none">
                      <li>
                        <Link
                          href="#"
                          className="flex items-center p-3 text-gray-700 hover:bg-gray-100"
                        >
                          <img
                            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1604623/trophy.png"
                            alt="Achievements"
                            className="w-5 h-5"
                          />
                          <span className="ml-2">Your Queries</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          href="#"
                          className="flex items-center p-3 text-gray-700 hover:bg-gray-100"
                        >
                          <img
                            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1604623/team.png"
                            alt="Team"
                            className="w-5 h-5"
                          />
                          <span className="ml-2">Sell Product</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/admin"}
                          className="flex items-center p-3 text-gray-700 hover:bg-gray-100"
                        >
                          <img
                            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1604623/book.png"
                            alt="Log History"
                            className="w-5 h-5"
                          />
                          <span className="ml-2">Admin</span>
                        </Link>
                      </li>
                    </ul>

                    <div className="border-t border-gray-300">
                      <Link
                        href="#"
                        className="flex items-center p-3 text-red-600 hover:bg-gray-100"
                      >
                        Logout
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </li> */}
          </ul>
        </div>
      </div>
      {/* {SearchBar && (
        <div className="flex flex-col px-64 ">
          <div
            className="bg-white items-center  justify-between w-full flex rounded-full shadow-lg p-1 mb-5 sticky"
            // style={{ top: "5px" }}
          >
            <input
              className="font-bold uppercase rounded-full w-full py-3 pl-4 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline lg:text-sm text-xs"
              type="text"
              placeholder="Search for parts and accessories"
            />

            <div className="bg-gray-600 p-2 hover:bg-blue-400 cursor-pointer mx-2 rounded-full"
            
            >
              <svg
                className="w-6 h-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
      )} */}
    </nav>
  );
};

export default Navbar;
