import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import HomePage from "./pages/HomePage";
import Product from "./pages/Product";
import AdminPage from "./components/AdminPage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Login from "./pages/Login";
import SignupPage from "./pages/Signup";
import SearchComponent from "./components/searchComponent/SearchComponent";
import AddProduct from "./components/AddProduct";
import ProductPage from "./pages/ProductPage";
import AIbot from "./components/AIbot";
import WithAuth from "./pages/withAuth";
// import WithAuth from "./pages/WithAuth";

function App() {
  // const { t, i18n } = useTranslation(); // Destructure t and i18n from the useTranslation hook

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/product/:id",
      element: (
        <>
          <Navbar />
          <ProductPage />
          <AIbot />
          <Footer />
        </>
      ),
    },
    {
      path: "/categories/:id",
      element: <Product />,
    },
    {
      path: "/admin",
      element: (
        <>
          <Navbar />
          {/* <WithAuth> */}
           <WithAuth />
          {/* </WithAuth> */}
          <AIbot />
          <Footer />
        </>
      ),
    },
    {
      path: "/login",
      element: (
        <>
          <Navbar />
          <Login />
          <AIbot />
          <Footer />
        </>
      ),
    },
    {
      path: "/signup",
      element: (
        <>
          <Navbar />
          <SignupPage />
          <AIbot />
          <Footer />
        </>
      ),
    },
    {
      path: "/addproduct",
      element: (
        <>
          <Navbar />
          <AddProduct />
          <AIbot />
          <Footer />
        </>
      ),
    },
    {
      path: "/search",
      element: (
        <>
          <Navbar />
          <SearchComponent />
          <AIbot />
          <Footer />
        </>
      ),
    },
    {
      path: "/search/:category",
      element: (
        <>
          <Navbar />
          <SearchComponent />
          <AIbot />
          <Footer />
        </>
      ),
    },
  ]);

  // const changeLanguage = (lng) => {
  //   // Ensure that `i18n.changeLanguage` is called on the i18n instance correctly
  //   if (i18n.changeLanguage) {
  //     i18n.changeLanguage(lng);  // Change language on button click
  //   }
  // };

  return (
    <div className="xl:max-w-7xl lg:max-w-4xl mx-auto py-4">
      {/* Language Switcher Buttons
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button onClick={() => changeLanguage('en')} style={{ margin: '0 10px' }}>
          {t('english')}
        </button>
        <button onClick={() => changeLanguage('hi')} style={{ margin: '0 10px' }}>
          {t('hindi')}
        </button>
      </div> */}

      <RouterProvider router={router} />
    </div>
  );
}

export default App;
