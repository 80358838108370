import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';



export default function ImageGallery({ image, thumbnail }) {
  const [currentImage, setCurrentImage] = useState(0);
  let allImages = [thumbnail, ...(image || [])].filter(Boolean);

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % allImages.length);
  };

  const previousImage = () => {
    setCurrentImage((prev) => (prev - 1 + allImages.length) % allImages.length);
  };

  if(typeof(image) === 'string') {
    allImages =  []
    allImages.push(image);
  }

  if (!allImages.length) return null;

  return (
    <div className=" h-[450px] lg:h-1/2">
      {/* Main Image */}
      <div className="relative h-full">
        <img
          src={allImages[currentImage]}
          alt="Vehicle"
          className="w-full flex  h-full object-contain"
        />
        
        {allImages.length > 1 && (
          <>
            {/* Navigation Arrows */}
            <button
              onClick={previousImage}
              className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 hover:bg-white shadow-lg transition-all"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <button
              onClick={nextImage}
              className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 hover:bg-white shadow-lg transition-all"
            >
              <ChevronRight className="w-6 h-6" />
            </button>

            {/* Thumbnail Strip */}
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
              {allImages.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentImage(index)}
                  className={`w-2 h-2 rounded-full transition-all ${
                    currentImage === index ? 'bg-white scale-125' : 'bg-white/50'
                  }`}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}