import React, { useState, useEffect, useRef } from "react";
import { ChevronDown, Check } from "lucide-react";

const CustomDropdown = ({
  options,
  selected,
  onSelect,
  placeholder,
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`
          w-full px-4 py-3 rounded-xl text-left transition-all duration-300
          flex items-center justify-between gap-2 bg-gray-50 border-2
          ${isOpen ? "border-blue-300 ring-2 ring-blue-100" : "border-gray-200"}
          ${selected ? "text-gray-900" : "text-gray-500"}
          hover:bg-gray-100
        `}
      >
        <span>{selected || placeholder}</span>
        <ChevronDown
          className={`w-5 h-5 text-gray-400 transition-transform duration-300 ${
            isOpen ? "transform rotate-180" : ""
          }`}
        />
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-2 bg-white rounded-xl shadow-lg border border-gray-200 max-h-60 overflow-auto">
          {options.map((option, index) => (
            <button
              key={index}
              onClick={() => {
                onSelect(option);
                setIsOpen(false);
              }}
              className={`
                w-full px-4 py-3 text-left flex items-center justify-between
                hover:bg-gray-50 transition-colors duration-200
                ${selected === option ? "text-blue-600 bg-blue-50" : "text-gray-700"}
              `}
            >
              <span>{option}</span>
              {selected === option && <Check className="w-4 h-4" />}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
