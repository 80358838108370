import React from "react";

interface VehicleType {
  id: string;
  label: string;
  icon: React.ReactNode;
}

interface Props {
  types: VehicleType[];
  selected: string;
  onChange: (type: string) => void;
}

const VehicleTypeSelector = ({ types, selected, onChange }: Props) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 sm:gap-3 md:gap-4">
  {types.map(({ id, label, icon }) => (
    <button
      key={id}
      onClick={() => onChange(id)}
      className={`
        flex items-center gap-2 sm:gap-3 p-3 md:p-4 rounded-xl transition-all duration-300
        ${selected === id
          ? "bg-blue-50 text-blue-600 border-2 border-blue-200 shadow-md transform scale-105"
          : "bg-gray-50 text-gray-600 border-2 border-gray-100 hover:bg-gray-100"
        }
      `}
    >
      {icon}
      <span className="font-medium text-sm sm:text-base">{label}</span>
    </button>
  ))}
</div>

  );
};

export default VehicleTypeSelector;
